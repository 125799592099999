// ---- Module Import --------------------------------------------------------------------

import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
// ---- Icons Importing ------------------------------------------------------------------
import { IoMdMenu } from 'react-icons/io';
import { animated, useTransition } from 'react-spring';
import LogoDP from '../../../assets/logos/logo_dp.png';
import { useResponsiveness } from '../../../hooks/Responsiveness';
import { UseSideMenuStatus } from '../../../hooks/SideMenuStatus';
import usePersistedState from '../../../utils/usePersistedState';
import DashboardCollapsedSidebar from '../../SidebarDashboard/collapsedSidebar/CollapsedSidebar';
import CollapsedSidebar from './CollapsedSidebar';
import * as S from './styled.colapsedSidebar';

// --------------------------------------------------------------------------------------//
//                                                                                      //
//                                Capsed Sidebar Section                                //
//                                                                                      //
// --------------------------------------------------------------------------------------//

// ---- Component Section ----------------------------------------------------------------
interface cellphoneHeaderProps {
  absolute?: boolean;
  dashboard?: boolean;
}
const HeaderCellphone: React.FC<cellphoneHeaderProps> = ({
  absolute,
  dashboard
}) => {
  // ---- Hooks ----------------------------------------------------------------------------

  const [extendedMenu, setExtendedMenu] = usePersistedState(
    'menuExpanded',
    false
  );

  const {
    ScreenMD,
    ShowMenuExpandedOnMobileState,
    setShowMenuExpandedOnMobileState,
    SetIsCollapsedMobileMenuVisible,
    IsCollapsedMobileMenuVisible
  } = useResponsiveness();

  const { setMenuExtendedDeclarative } = UseSideMenuStatus();

  const HandleMenu = () => {
    setShowMenuExpandedOnMobileState(true);
    setMenuExtendedDeclarative({ state: true });
  };

  useEffect(() => {
    ScreenMD && extendedMenu === false && setExtendedMenu(true);
  }, [extendedMenu, ScreenMD, setExtendedMenu]);

  useEffect(() => {
    !ScreenMD && setShowMenuExpandedOnMobileState(false);
  }, [ScreenMD, setShowMenuExpandedOnMobileState]);

  const [prevScrollPos, setPrevScrollPos] = useState(0);

  // ---- Handle Scroll --------------------------------------------------------------------

  const handleScroll = useCallback(() => {
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    // set state based on location info (explained in more detail below)
    if (prevScrollPos > 30) {
      SetIsCollapsedMobileMenuVisible(prevScrollPos > currentScrollPos);
    }

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  }, [SetIsCollapsedMobileMenuVisible, prevScrollPos]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, IsCollapsedMobileMenuVisible, handleScroll]);

  // ---- Handle Transitions ---------------------------------------------------------------

  const transitionsMenuOptions = useTransition(
    ShowMenuExpandedOnMobileState,
    null,
    {
      from: { position: 'absolute', left: '-15rem' },
      enter: { position: 'absolute', left: '0%' },
      leave: { position: 'absolute', left: '-15rem' }
    }
  );

  const transitionsMobileMenu = useTransition(
    !ShowMenuExpandedOnMobileState && IsCollapsedMobileMenuVisible,
    null,
    {
      from: { position: 'absolute', top: '-4rem' },
      enter: { position: 'absolute', top: '0' },
      leave: { position: 'absolute', top: '-5rem' }
    }
  );

  // const FadeBlack = useTransition(ShowMenuExpandedOnMobileState, null, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 0.5 },
  //   leave: { opacity: 0 }
  // });

  return (
    <>
      {/* {FadeBlack.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={{ ...props, zIndex: 9000 }}>
              <S.BlackBackground />
            </animated.div>
          )
      )} */}
      {transitionsMobileMenu.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={{ ...props, zIndex: 10000 }}>
              <S.CellphoneMenu absolute={absolute || false}>
                <Row>
                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '4rem',
                      position: 'relative'
                    }}
                  >
                    <img
                      src={LogoDP}
                      alt="Direito Prático"
                      style={{ height: '3rem' }}
                      onClick={HandleMenu}
                    />
                    <button
                      onClick={HandleMenu}
                      style={{
                        right: '2rem',
                        top: '1rem',
                        background: 'none',
                        border: 'none',
                        position: 'absolute',
                        cursor: 'pointer'
                      }}
                    >
                      <IoMdMenu size={34} color="#f1f1f1" />
                    </button>
                  </Col>
                </Row>
              </S.CellphoneMenu>
            </animated.div>
          )
      )}

      {transitionsMenuOptions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={{ ...props, zIndex: 10000 }}>
              {dashboard || false ? (
                <DashboardCollapsedSidebar />
              ) : (
                <CollapsedSidebar />
              )}
            </animated.div>
          )
      )}
    </>
  );
};
export default HeaderCellphone;
