import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import 'react-credit-cards/es/styles-compiled.css';
import { Col, message, Row, Space } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cards from 'react-credit-cards';
import PaymentIcon from 'react-payment-icons';
import * as Yup from 'yup';
import Loading from '../../../../../components/Loading/Loading';
import { StyledButton } from '../../../../../components/SuperButton/styles';
import TransparentInput from '../../../../../components/TransparentInput/InputIndex';
import TransparentInputMask from '../../../../../components/TransparentInputMask/TransparentInputMask';
import { useLawyerData } from '../../../../../hooks/lawyerData';
import useScript from '../../../../../hooks/Scripts';
import { useToast } from '../../../../../hooks/Toast';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import {
  IuguCreatePaymentMethod,
  PaymentMethodProps
} from '../../../../../utils/IuguFunctions/PaymentMethods';
import {
  ConfigureIugu,
  CreateIuguPaymentToken,
  toUpperCase,
  validateFields
} from '../functions';
import * as S from '../styled.Payment';
import { ILawyerInformation } from '../../../../../@types/ILawyerInformation';
import Pixel from '../../../../../utils/Ads/FacebookPixel';
import GoogleAnalytics from '../../../../../utils/Ads/GoogleAnalytics';
declare const Iugu: any;

interface CreditCardFormProps {
  SubmitCallback?(data: PaymentMethodProps): void;
  SubmitButtonText: string;
  set_as_default: 'true' | 'false';
  NoSuccessAddedCardNotification?: boolean;
}
const AddNewCreditCardPaymentMethodForm: React.FC<CreditCardFormProps> = ({
  SubmitCallback,
  SubmitButtonText,
  set_as_default,
  NoSuccessAddedCardNotification
}) => {
  const { addToast } = useToast();

  const [LoadingState, SetLoadingState] = useState(true);

  const formRef = useRef<FormHandles>(null);

  const { updateLawyerData } = useLawyerData();

  // ---- Form States ----------------------------------------------------------------------

  const [cvc, SetCvc] = useState('');
  const [Expiry, SetExpiry] = useState('');
  const [Focus, SetFocus] = useState<
    'name' | 'number' | 'cvc' | 'expiry' | undefined
  >('name' as 'name' | 'number' | 'cvc' | 'expiry' | undefined);
  const [Name, SetName] = useState('');
  const [Number, SetNumber] = useState('');
  const [IsFilled, SetIsFilled] = useState(false);
  const [LawyerData, SetLawyerData] = useState<ILawyerInformation>(
    {} as ILawyerInformation
  );
  useEffect(() => {
    SetLoadingState(true);
    updateLawyerData().then(res => {
      if (res) {
        SetLawyerData(res.data);
        return SetLoadingState(false);
      }
      return message.error('Erro, recarregue a página');
    });
  }, []);

  // ---- Loading Iugu Scripts -------------------------------------------------------------

  const status = useScript(`https://js.iugu.com/v2`);

  useEffect(() => {
    if (typeof Iugu !== 'undefined') {
      ConfigureIugu(Iugu);
      SetLoadingState(false);
    }
  }, [status]);

  // ---- Handle Disable/Enable ConfirmButton -----------------------------------------------

  useEffect(() => {
    if (Name.length && Number.length && cvc.length && Expiry.length) {
      SetIsFilled(true);
    }
  }, [Expiry, Name, Number, cvc]);

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                     HandleSubmit                                     //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});

      try {
        SetLoadingState(true);

        // ---- Validate Form --------------------------------------------------------------------

        const schema = Yup.object().shape({
          cvc: Yup.string().required('Preencha este campo.'),
          expiry: Yup.string().required('Preencha este campo.'),
          name: Yup.string().required('Preencha este campo.'),
          cardNumber: Yup.string().required('Preencha este campo.')
        });

        await schema.validate(data, {
          abortEarly: false
        });

        // ---- Fix Form key Data ----------------------------------------------------------------

        const UserNameStrings = data.name.split(' ');

        const FirstName = UserNameStrings[0];

        delete UserNameStrings[0];
        const LastName = UserNameStrings.join(' ').substring(1);

        const SplitDateExpiry = (data.expiry as string).split('/');

        // ---- CreateIuguPaymentToken -----------------------------------------------------------

        return CreateIuguPaymentToken({
          CreditCardNumber: Number,
          ExpiryMonth: SplitDateExpiry[0],
          ExpiryYear: SplitDateExpiry[1],
          firstName: FirstName,
          lastName: LastName,
          Cvv: data.cvc,
          Iugu,
          message,
          SetLoadingState,
          callback: async token => {
            if (!LawyerData.user_payment_reference) {
              SetLoadingState(false);
              return message.error('Erro desconhecido, tente novamente');
            }
            if (!token) {
              SetLoadingState(false);
              return message.error('Erro ao processar o cartão');
            }
            // ---- Create Iugu Payment Method -------------------------------------------------------

            IuguCreatePaymentMethod({
              user_iugu_account_reference_id: LawyerData.user_payment_reference,
              set_as_default,
              token,
              description: 'Credit Card'
            }).then(async res => {
              if (res) {
                SubmitCallback && (await SubmitCallback(res.data));
                if (!NoSuccessAddedCardNotification) {
                  message.success('Cartão processado com sucesso');
                }
                Pixel.track('AddPaymentInfo');
                GoogleAnalytics.event({
                  category: 'Subscriptions',
                  action: 'Added Payment Info'
                });
                return SetLoadingState(false);
              }
              SetLoadingState(false);
              return message.error(
                'Houve um erro ao adicionar o método de pagamento, tente novamente'
              );
            });
          }
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        }
        SetLoadingState(false);
        message.error('Houve um erro, tente novamente');
      }
    },
    [LawyerData.user_payment_reference, Number, SubmitCallback, set_as_default]
  );

  // ---- Handle Form and Card -------------------------------------------------------------

  function onFocusCard(fieldName: 'name' | 'number' | 'cvc' | 'expiry') {
    SetFocus(fieldName);
  }

  function getFieldValues(fieldName: 'name' | 'number' | 'cvc' | 'expiry') {
    const nameValue = formRef.current?.getFieldValue('name') || '';
    const cvcValue = formRef.current?.getFieldValue('cvc') || '';
    const expiryValue = formRef.current?.getFieldValue('expiry') || '';
    const numberValue = formRef.current?.getFieldValue('cardNumber') || '';
    SetName(nameValue);
    SetNumber(numberValue);
    SetCvc(cvcValue);
    SetExpiry(expiryValue);

    SetFocus(fieldName);

    if (fieldName === 'name') {
      validateFields({ fieldName, formRef, value: nameValue, Iugu });
    } else if (fieldName === 'number') {
      validateFields({ fieldName, formRef, value: numberValue, Iugu });
    } else if (fieldName === 'cvc') {
      validateFields({ fieldName, formRef, value: cvcValue, Iugu });
    } else if (fieldName === 'expiry') {
      validateFields({ fieldName, formRef, value: expiryValue, Iugu });
    }
  }

  return (
    <>
      <Loading loading={LoadingState}>
        <Row gutter={[32, 32]}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Cards
              cvc={cvc}
              expiry={Expiry}
              name={Name}
              number={Number}
              focused={Focus}
              placeholders={{
                name: 'SEU NOME AQUI'
              }}
              locale={{ valid: 'Válido até:' }}
              issuer="Houve um Erro"
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <S.CreditCardFormStyled>
              <Form onSubmit={handleSubmit} ref={formRef}>
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: '100%', maxWidth: '30rem' }}
                >
                  <h3>Dados do Cartão</h3>
                  <Space>
                    <PaymentIcon id="visa" style={{ width: 50 }} />
                    <PaymentIcon id="mastercard" style={{ width: 50 }} />
                    <PaymentIcon id="discover" style={{ width: 50 }} />
                    <PaymentIcon id="elo" style={{ width: 50 }} />
                    <PaymentIcon id="diners" style={{ width: 50 }} />
                  </Space>
                  <TransparentInput
                    name="name"
                    placeholder="Nome"
                    label="Nome"
                    onFocusCapture={() => onFocusCard('name')}
                    onChange={(e: any) => {
                      getFieldValues('name');
                      toUpperCase({ e, SetNumber, formRef });
                    }}
                  />
                  <TransparentInput
                    name="cardNumber"
                    onFocusCapture={() => onFocusCard('number')}
                    placeholder="Numero do Cartão"
                    label="Numero do Cartão"
                    onChange={() => {
                      getFieldValues('number');
                    }}
                  />
                  <TransparentInputMask
                    name="expiry"
                    onFocusCapture={() => onFocusCard('expiry')}
                    mask="99/99"
                    placeholder="MM/AA"
                    label="Expiração"
                    labelDefaultVisible
                    onChange={() => getFieldValues('expiry')}
                  />
                  <TransparentInput
                    name="cvc"
                    onFocusCapture={() => onFocusCard('cvc')}
                    placeholder="CVV"
                    label="CVV"
                    onChange={() => getFieldValues('cvc')}
                  />
                  <StyledButton disabled={!IsFilled}>
                    {SubmitButtonText}
                  </StyledButton>
                </Space>
              </Form>
            </S.CreditCardFormStyled>
          </Col>
        </Row>
      </Loading>
    </>
  );
};

export default AddNewCreditCardPaymentMethodForm;
