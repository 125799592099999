import { AxiosRequestConfig } from 'axios';
import querystring from 'query-string';
import { WalletModel } from '../../@types/models/Wallets';

import api from '../api';
interface ResponseProps {
  totalCount: number;
  data: WalletModel[];
}

export class WalletService {
  config: AxiosRequestConfig = {
    headers: {
      sub_account_request: 'true'
    }
  };

  async GetUserWallets() {
    return api.get<ResponseProps>('/gateway/wallets');
  }

  async GetWalletActivity(queryData?: any) {
    if (queryData) {
      const paramsString = querystring.stringify(queryData);
      const response = api.get<WalletActivityListProps>(
        `/gateway/wallets/activity?${paramsString}`,
        this.config
      );
      return response;
    }

    const response = api.get<WalletActivityListProps>(
      `/gateway/wallets/activity`,
      this.config
    );
    return response;
  }
}

export interface WalletActivityListProps {
  walletId: string;
  totalCount: number;
  limit: number;
  offset: number;
  hasMore: boolean;
  data: WalletActivityItemProps[];
}

export interface WalletActivityItemProps {
  id: string;
  type: string;
  user_id: string;
  wallet_id: string;
  reference_id: string;
  references_related: string[];
  users_related: string[];
  tags: string[];
  data: any;
  additional_details: any;
  new_data_sets: any[];
  created_at: string;
  updated_at: string;
}
