import { AxiosResponse } from 'axios';
import { ILawyerInformation } from '../../@types/ILawyerInformation';
import api from '../../services/api';

export async function GetRandomPartneshipLawyers(): Promise<
  AxiosResponse<ILawyerInformation[]>
> {
  return await api.get('/lawyer/random');
}

export async function GetLawyerInformation(
  id: string
): Promise<AxiosResponse<ILawyerInformation>> {
  return await api.get<ILawyerInformation>(`/lawyer/id/${id}`);
}

export async function EditLawyerInformation(
  Lawyer_id: string,
  DataAPI: Omit<
    Partial<ILawyerInformation>,
    'lawyer_id' | 'updated_at' | 'created_at'
  >
): Promise<AxiosResponse<ILawyerInformation>> {
  const data = {
    lawyer_id: Lawyer_id,
    ...DataAPI
  };
  return await api.post<ILawyerInformation>(`/lawyer`, data);
}
interface EditLawyerAvatarProps {
  lawyerId: string;
  avatarName: string;
}
export function EditLawyerAvatar({
  avatarName,
  lawyerId
}: EditLawyerAvatarProps): Promise<AxiosResponse<ILawyerInformation>> {
  const DataAPI = {
    lawyer_id: lawyerId,
    avatar: avatarName
  };
  return api.patch(`/lawyer/avatar`, DataAPI);
}
interface VerifyCPFResponse {
  available: boolean;
}
export function VerifyLawyerCPF(
  cpf: string
): Promise<AxiosResponse<VerifyCPFResponse>> {
  return api.get(`/lawyer/verify/cpf/${cpf}`);
}
