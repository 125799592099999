// ---- Module Import --------------------------------------------------------------------

import React, { useEffect, useState } from 'react';
import {
  AiFillFolderOpen,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold
} from 'react-icons/ai';
import { BsCameraVideoFill, BsFillCalendarFill } from 'react-icons/bs';
import { FaFileSignature, FaSearch } from 'react-icons/fa';
import { GoGitPullRequest, GoLaw } from 'react-icons/go';
// ---- Icons Importing ------------------------------------------------------------------
import { IoIosPeople, IoMdClose, IoMdMenu } from 'react-icons/io';
import { MdDashboard, MdOutlineAttachMoney } from 'react-icons/md';
import { SiGooglemessages } from 'react-icons/si';
import { Link, useRouteMatch } from 'react-router-dom';
// ---- Content Importing --------------------------------------------------------------------
import LogoDPIcon from '../../../assets/logos/DPlogoWhiteIcon.svg';
import LogoDPPNG from '../../../assets/logos/logo_dp.png';
import { useAuth } from '../../../hooks/auth';
import { useResponsiveness } from '../../../hooks/Responsiveness';
import { UseSideMenuStatus } from '../../../hooks/SideMenuStatus';
import { env } from '../../../utils/EnviromentVariables';
import {
  CollapsedSidebarMenu,
  CollapseMenu,
  GeralMenu,
  HeaderMobile,
  LogoDP,
  LogoDPExtended,
  Menu,
  MenuItens,
  MenuMobile,
  SpanComingSoon,
  StyledLink,
  StyledLinkA
} from './styled.colapsedSidebar';

// --------------------------------------------------------------------------------------//
//                                                                                      //
//                                Capsed Sidebar Section                                //
//                                                                                      //
// --------------------------------------------------------------------------------------//

// ---- Component Section ----------------------------------------------------------------

const DashboardCollapsedSidebar: React.FC = () => {
  // ---- Hooks ----------------------------------------------------------------------------

  // const [extendedMenu, setExtendedMenu] = usePersistedState(
  //   'menuExpanded',
  //   false
  // );
  const { user } = useAuth();
  const { setMenuExtended, isExtended } = UseSideMenuStatus();
  const { ScreenMD, setShowMenuExpandedOnMobileState } = useResponsiveness();

  const [PageName, SetPageName] = useState('empty');
  const { url } = useRouteMatch();

  useEffect(() => {
    const namePage =
      url
        .split('/')
        .filter(item => item !== '')
        .find(item => item !== 'painel' && item !== 'escritorio') || '';
    SetPageName(namePage);
  }, [url]);

  // ---- Functions ------------------------------------------------------------------------

  function handleExtendMenuState() {
    setMenuExtended();
  }
  function handleCloseMenuOnClickMobile(): void {
    ScreenMD && setShowMenuExpandedOnMobileState(false);
  }

  const styled = {
    soon: (
      <>
        {!isExtended && (
          <>
            <span
              style={{
                fontSize: '0.7rem',
                paddingTop: '0.4rem',
                marginLeft: '0.3rem',
                fontWeight: 600
              }}
            >
              Em breve
            </span>
          </>
        )}
      </>
    )
  };
  // ---- Component -----------------------------------------------------------------------
  const IconsSize = 20;
  return (
    <div
      className=""
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        position: 'fixed',
        height: '100vh',
        zIndex: 1000
      }}
    >
      <GeralMenu>
        <CollapsedSidebarMenu extendedMenu={isExtended}>
          <HeaderMobile>
            <MenuMobile
              onClick={() => {
                handleExtendMenuState();
                setMenuExtended();
              }}
            >
              <IoMdMenu size={IconsSize} />
            </MenuMobile>
            <Link to="/" onClick={handleCloseMenuOnClickMobile}>
              <LogoDP extendedMenu={isExtended}>
                <img src={LogoDPIcon} alt="Logo DP" />
              </LogoDP>
              <LogoDPExtended extendedMenu={isExtended}>
                <img src={LogoDPPNG} alt="Logo DP" />
              </LogoDPExtended>
            </Link>
          </HeaderMobile>
          <Menu>
            <MenuItens>
              {isExtended ? (
                <CollapseMenu>
                  <button
                    onClick={() => {
                      !ScreenMD && handleExtendMenuState();
                      !ScreenMD && setMenuExtended();
                      ScreenMD && setShowMenuExpandedOnMobileState(false);
                    }}
                  >
                    {ScreenMD ? (
                      <IoMdClose size={IconsSize} />
                    ) : (
                      <AiOutlineMenuFold size={IconsSize} />
                    )}
                    <span className="script">Encolher Menu</span>
                  </button>
                </CollapseMenu>
              ) : (
                <StyledLink
                  extendedMenu={isExtended}
                  onClick={() => {
                    handleExtendMenuState();
                    setMenuExtended();
                  }}
                  to="#"
                  active={false}
                >
                  <button>
                    {isExtended ? '' : ''}
                    <AiOutlineMenuUnfold size={IconsSize} />
                    <div>{isExtended ? '' : 'Expandir Menu'}</div>
                  </button>
                </StyledLink>
              )}

              <StyledLink
                active={!PageName}
                extendedMenu={isExtended}
                to={
                  user.type === 'lawyer'
                    ? '/escritorio'
                    : user.type === 'user'
                    ? '/painel'
                    : '#'
                }
                onClick={() => {
                  handleCloseMenuOnClickMobile();
                }}
              >
                <MdDashboard size={IconsSize} />
                <div>Dashboard</div>
              </StyledLink>

              <StyledLink
                active={PageName === 'agenda'}
                extendedMenu={isExtended}
                to={
                  user.type === 'lawyer'
                    ? '/escritorio/agenda'
                    : user.type === 'user'
                    ? '/painel/agenda'
                    : '#'
                }
                onClick={() => {
                  handleCloseMenuOnClickMobile();
                }}
              >
                <BsFillCalendarFill size={IconsSize} />
                <div>Agenda</div>
              </StyledLink>
              <StyledLink
                active={PageName === 'sign'}
                extendedMenu={isExtended}
                to="#"
                onClick={() => {
                  handleCloseMenuOnClickMobile();
                }}
              >
                <FaFileSignature size={IconsSize} />
                <div>Assinatura Eletrônica {styled.soon} </div>

                <SpanComingSoon extendedMenu={isExtended}>
                  Em breve
                </SpanComingSoon>
              </StyledLink>

              {/* {user.type === 'lawyer' && (
              <>
                <StyledLink
                  extendedMenu={isExtended}
                  to="#"
                  onClick={handleCloseMenuOnClickMobile}
                >
                  <SiBuzzfeed size={IconsSize} />
                  <div>Analytics {styled.soon} </div>
                  <SpanComingSoon extendedMenu={isExtended}>
                    Em breve
                  </SpanComingSoon>
                </StyledLink>
              </>
            )} */}
              <>
                {/* <StyledLink
                extendedMenu={isExtended}
                to="#"
                onClick={handleCloseMenuOnClickMobile}
              >
                <SiGooglemessages size={IconsSize} />
                <div>Avaliações</div>
              </StyledLink> */}

                <StyledLink
                  active={PageName === 'chat'}
                  extendedMenu={isExtended}
                  to={
                    user.type === 'user'
                      ? '/painel/chat'
                      : user.type === 'lawyer'
                      ? '/escritorio/chat'
                      : ''
                  }
                  onClick={() => {
                    handleCloseMenuOnClickMobile();
                  }}
                >
                  <SiGooglemessages size={IconsSize} />
                  <div>Chat</div>
                </StyledLink>

                {user.type === 'lawyer' && (
                  <StyledLink
                    active={PageName === 'conferencias'}
                    extendedMenu={isExtended}
                    to={
                      user.type === 'lawyer'
                        ? '/escritorio/conferencias'
                        : user.type === 'user'
                        ? '/painel/conferencias'
                        : '#'
                    }
                    onClick={() => {
                      handleCloseMenuOnClickMobile();
                    }}
                  >
                    <BsCameraVideoFill size={IconsSize} />
                    <div>Conferências</div>
                  </StyledLink>
                )}

                {/* <StyledLink
                extendedMenu={isExtended}
                to="/escritorio/contratos"
                onClick={handleCloseMenuOnClickMobile}
              >
                <CgFileDocument size={IconsSize} />
                <div>
                  {isExtended ? (
                    <>
                      Contrato
                      <br />
                      Prático
                    </>
                  ) : (
                    <>Contrato Prático</>
                  )}
                </div>
              </StyledLink> */}
                {user.type === 'lawyer' && (
                  <>
                    <StyledLink
                      active={PageName === 'advogados'}
                      extendedMenu={isExtended}
                      to="/escritorio/advogados"
                      onClick={() => {
                        handleCloseMenuOnClickMobile();
                      }}
                    >
                      <GoLaw size={IconsSize} />
                      <div>
                        {isExtended ? (
                          <>
                            Correspondentes
                            <br />
                            Jurídicos
                          </>
                        ) : (
                          <>Correspondentes Jurídicos</>
                        )}
                      </div>
                    </StyledLink>

                    {/* <StyledLink
                    extendedMenu={isExtended}
                    to="#"
                    onClick={handleCloseMenuOnClickMobile}
                  >
                    <HiOfficeBuilding size={IconsSize} />
                    <div>Escritório</div>
                  </StyledLink> */}
                  </>
                )}
              </>

              <StyledLink
                active={PageName === 'demandas'}
                extendedMenu={isExtended}
                to={
                  user.type === 'lawyer'
                    ? '/escritorio/demandas'
                    : user.type === 'user'
                    ? '/painel/demandas'
                    : '#'
                }
                onClick={() => {
                  handleCloseMenuOnClickMobile();
                }}
              >
                <GoGitPullRequest size={IconsSize} />
                <div>Demandas</div>
              </StyledLink>
              {user.type === 'user' && (
                <>
                  <StyledLink
                    active={PageName === 'advogados'}
                    extendedMenu={isExtended}
                    to="/painel/advogados"
                    onClick={() => {
                      handleCloseMenuOnClickMobile();
                    }}
                  >
                    <IoIosPeople size={IconsSize} />
                    <div>Encontre Advogados</div>
                  </StyledLink>
                </>
              )}

              <StyledLink
                active={PageName === 'registros'}
                extendedMenu={isExtended}
                to={
                  user.type === 'user'
                    ? '/painel/registros'
                    : user.type === 'lawyer'
                    ? '/escritorio/registros'
                    : ''
                }
                onClick={() => {
                  handleCloseMenuOnClickMobile();
                }}
              >
                <AiFillFolderOpen size={IconsSize} />
                <div>Documentos</div>
              </StyledLink>
              {user.type === 'lawyer' && (
                <>
                  <StyledLinkA
                    active={PageName === 'gateway'}
                    extendedMenu={isExtended}
                    href={env.app_dashboard + '/escritorio/gateway'}
                    onClick={() => {
                      handleCloseMenuOnClickMobile();
                    }}
                  >
                    <MdOutlineAttachMoney size={IconsSize} />
                    <div>Pagamentos</div>
                  </StyledLinkA>
                  <StyledLinkA
                    active={PageName === 'juridico'}
                    extendedMenu={isExtended}
                    href={env.app_dashboard + '/escritorio/juridico'}
                    onClick={() => {
                      handleCloseMenuOnClickMobile();
                    }}
                  >
                    <FaSearch size={IconsSize} />
                    <div>Processos</div>
                  </StyledLinkA>
                </>
              )}

              {/* {user.type === 'lawyer' && (
                <>
                  <StyledLink
                    active={PageName === 'rewards'}
                    extendedMenu={isExtended}
                    to="#"
                    onClick={() => {
                      handleCloseMenuOnClickMobile();
                    }}
                    style={{ paddingBottom: '0.5rem' }}
                  >
                    <BsTrophy size={IconsSize} />
                    <div>Rewards {styled.soon} </div>
                    <SpanComingSoon
                      extendedMenu={isExtended}
                      style={{ bottom: '0.4rem' }}
                    >
                      Em breve
                    </SpanComingSoon>
                  </StyledLink>
                </>
              )} */}
            </MenuItens>
            <div className="footerMenu"></div>
          </Menu>
        </CollapsedSidebarMenu>
      </GeralMenu>
    </div>
  );
};
export default DashboardCollapsedSidebar;
