import React from 'react';
import { Row, Col, Space } from 'antd';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import 'dotenv/config';
const GoogleProvider: React.FC = ({ children }) => {
  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY || ''}
        language="pt-BR"
      >
        {children}
      </GoogleReCaptchaProvider>
    </>
  );
};

export default GoogleProvider;
