import React from 'react';
import styled, { css } from 'styled-components';
import { useResponsiveness } from '../../hooks/Responsiveness';
import { UseSideMenuStatus } from '../../hooks/SideMenuStatus';
import CollapsedSidebar from './collapsedSidebar/CollapsedSidebar';
import HeaderCellphone from './collapsedSidebar/HeaderCellphone';

export const CPSidebar: React.FC = () => {
  const { ScreenMD } = useResponsiveness();
  return <>{ScreenMD ? <HeaderCellphone /> : <CollapsedSidebar />}</>;
};

interface SideMenuStatus {
  SideMenuExtended: boolean;
}

const MenuStates = {
  Extended: css`
    padding-left: 15rem;
  `,
  Collapsed: css`
    padding-left: 3.5rem;
  `
};

const StyledContentSection = styled.div<SideMenuStatus>`
  transition: all 0.5s;
  overflow-x: hidden;
  ${props =>
    props.SideMenuExtended ? MenuStates.Extended : MenuStates.Collapsed};

  @media only screen and (max-width: 1021px) {
    padding: 0;
  }
`;
const Space = styled.div`
  @media only screen and (max-width: 768px) {
    height: 4rem;
  }
`;
export const CPContentSection: React.FC = ({ children }) => {
  const { isExtended } = UseSideMenuStatus();

  return (
    <>
      <Space />
      <StyledContentSection SideMenuExtended={isExtended}>
        {children}
      </StyledContentSection>
    </>
  );
};
