import React, { createContext, useContext, useEffect, useState } from 'react';
import { IChats } from '../@types/Ichats';
import Backend from '../config/backendAdress';
import {
  ChatUserProps,
  NewContactSocketEventProps
} from '../pages/private/Shared/Chat/Chat';
import appSocket from '../services/webSocket';
import {
  AddChatToContacts,
  LoadContactInformation
} from '../utils/LoadChatAndContacts';
import { useAuth } from './auth';
import { useToast } from './Toast';

interface ContactContextProps {
  SetChats(contacts: React.SetStateAction<IChats[]>): void;
  Chats: IChats[];

  SetContacts(contacts: React.SetStateAction<ChatUserProps[]>): void;
  Contacts: ChatUserProps[];

  SetContactsLoadingState(contacts: React.SetStateAction<boolean>): void;
  ContactsLoadingState: boolean;
}

const ContactsDataContext = createContext<ContactContextProps>(
  {} as ContactContextProps
);

export const ContactsProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const [Contacts, SetContacts] = useState<ChatUserProps[]>(
    [] as ChatUserProps[]
  );

  const { addToast } = useToast();

  const [Chats, SetChats] = useState<IChats[]>([] as IChats[]);

  const [ContactsLoadingState, SetContactsLoadingState] = useState(true);

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                Load Contacts Handling                                //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  function LoadContacts() {
    SetContactsLoadingState(true);
    LoadContactInformation({
      user,
      ChatsCallback: chats => {
        SetChats(chats);
      },
      UsersCallback: users => {
        SetContacts(users);
        SetContactsLoadingState(false);
      }
    });
  }

  useEffect(() => {
    LoadContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       Sockets add contact handling                                        //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  useEffect(() => {
    appSocket.on('NewContact', (chat: NewContactSocketEventProps) => {
      addToast({
        title: 'Nova solicitação de contato',
        type: 'info',
        description: 'Confira o chat para saber mais'
      });
      new Audio(`${Backend.files}/${'system/notification-sound.mp3'}`).play();
      SetContacts(n => [...n, chat.UserProps]);
      SetChats(n => [...n, chat.chat]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       SocketIo On/offline Handling                                      //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  function disconnect(): void {
    if (user !== undefined && user.id !== undefined) {
      appSocket.emit('userDisconnecting', user.id);
    }
  }

  function connect() {
    if (user !== undefined && user.id !== undefined) {
      appSocket.emit('userConnect', user.id);
    }
  }

  window.onbeforeunload = () => {
    disconnect();
  };

  useEffect(() => {
    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       Return                                         //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  return (
    <ContactsDataContext.Provider
      value={{
        Chats,
        Contacts,
        ContactsLoadingState,
        SetChats,
        SetContacts,
        SetContactsLoadingState
      }}
    >
      {children}
    </ContactsDataContext.Provider>
  );
};

export function useContacts(): ContactContextProps {
  const context = useContext(ContactsDataContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
