import React, { createContext, useCallback, useContext } from 'react';
import { ThemeProvider as ThemeProviderStyledComponents } from 'styled-components';
import dark from '../styles/themes/dark';
import light from '../styles/themes/light';
import usePersistedState from '../utils/usePersistedState';

interface ThemeProviderProps {
  ThemeSwitcher(): void;
}
const SwitchTheme = createContext<ThemeProviderProps>({} as ThemeProviderProps);

export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = usePersistedState('theme', light as any);

  const ThemeSwitcher = useCallback(() => {
    setTheme(theme.title === 'dark' ? light : dark);
  }, [theme]);

  return (
    <SwitchTheme.Provider value={{ ThemeSwitcher }}>
      <ThemeProviderStyledComponents theme={light}>
        {children}
      </ThemeProviderStyledComponents>
    </SwitchTheme.Provider>
  );
};

export function useSwitchTheme(): ThemeProviderProps {
  const context = useContext(SwitchTheme);

  if (!context) {
    throw new Error('The SwitchTheme must be children of a provider.');
  }

  return context;
}
