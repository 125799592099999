export const Plans = {
  price: {
    partneshipMember: {
      monthly: '149,90',
      yearly: '1.618,92'
    },
    membership: {
      monthly: '79,90',
      yearly: '754,92'
    },
    professional: {
      monthly: '49,90',
      yearly: '430,92'
    }
  }
};

export const PlansInt = {
  price: {
    partneshipMember: {
      monthly: 149.9,
      yearly: 1618.92
    },
    membership: {
      monthly: 79.9,
      yearly: 754.92
    },
    professional: {
      monthly: 49.9,
      yearly: 430.92
    }
  }
};
