import { AxiosResponse } from 'axios';
import { IFiles, IFilesResponse, ISimpleUrlFiles } from '../../@types/IFiles';
import api from '../../services/api';

interface UploadSingleFileProps {
  file: File;
  type: 'miscellaneous' | 'records' | 'images' | 'chats' | 'avatars' | 'public';
}

export function UploadSingleFile({
  file,
  type
}: UploadSingleFileProps): Promise<AxiosResponse<IFilesResponse>> {
  const FilesData = new FormData();
  FilesData.append('files', file);
  if (type !== 'public') {
    return api.post<IFilesResponse>(`/files/private/${type}`, FilesData);
  } else {
    return api.post<IFilesResponse>(`/files/${type}`, FilesData);
  }
}

interface UploadMultiFileProps {
  files: File[];
  type: 'miscellaneous' | 'records' | 'images' | 'chats' | 'avatars' | 'public';
}

export function UploadMultiFiles({
  files,
  type
}: UploadMultiFileProps): Promise<AxiosResponse<IFilesResponse>> {
  const FilesData = new FormData();
  files.forEach(file => {
    FilesData.append('files', file);
  });
  if (type !== 'public') {
    return api.post<IFilesResponse>(`/files/private/${type}`, FilesData);
  } else {
    return api.post<IFilesResponse>(`/files/${type}`, FilesData);
  }
}
interface GetFileByFileNameProps {
  FileName: string;
}

export function GetFileByFileName({
  FileName
}: GetFileByFileNameProps): Promise<AxiosResponse<IFilesResponse>> {
  return api.patch<IFilesResponse>(`/files/get`, {
    fileName: FileName,
    simpleUrl: false
  });
}

export function GetSimpleUrlByFileName({
  FileName
}: GetFileByFileNameProps): Promise<AxiosResponse<ISimpleUrlFiles>> {
  return api.patch<ISimpleUrlFiles>(`/files/get`, {
    fileName: FileName,
    simpleUrl: true
  });
}

interface GetFileByIdProps {
  fileId: string;
}
export function GetFileById({
  fileId
}: GetFileByIdProps): Promise<AxiosResponse<IFilesResponse>> {
  return api.get<IFilesResponse>(`/files/get/id/${fileId}`);
}
interface restrictFileProps {
  fileId: string;
  allowed_users: string[];
}

export function RestrictFile({
  allowed_users,
  fileId
}: restrictFileProps): Promise<AxiosResponse<IFiles>> {
  return api.put<IFilesResponse>(`/files/restrict/${fileId}`, {
    allowed_users
  });
}

interface findFileDataInsideFileResponseProps {
  fileName: string;
  FileData: IFilesResponse;
}
export function FindFileDataInsideFileResponse({
  FileData,
  fileName
}: findFileDataInsideFileResponseProps) {
  const fileUrl = FileData.urls.find(item => item.key === fileName);
  const fileInfo = FileData.additional_information.FilesInfo.find(
    item => item.key === fileName
  );

  if (!fileUrl || !fileInfo) {
    return undefined;
  }
  return {
    info: fileInfo,
    url: fileUrl.url
  };
}
