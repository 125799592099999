import ReactPixel from 'react-facebook-pixel';
import 'dotenv/config';
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false // enable logs
};
ReactPixel.init(
  process.env.REACT_APP_FACEBOOK_PIXEL_ID || '',
  undefined,
  options
);

const Pixel = ReactPixel;

export default Pixel;
