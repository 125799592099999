import React, { lazy, Suspense } from 'react';
import PublicRoutes from './PublicRoutes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loading from '../components/Loading/Loading';
import PageNotFound from '../pages/public/NotFoundError/PageNotFound';
const PrivateRoutes = lazy(() => import('./PrivateRoutes'));

const Routes: React.FC = ({ children }) => {
  return (
    <>
      <Router>
        <PublicRoutes />
        <Suspense
          fallback={
            <>
              <Loading loading={true} fullPage>
                <div
                  style={{
                    height: '100vh',
                    width: '100vw'
                  }}
                ></div>
              </Loading>
            </>
          }
        >
          <PrivateRoutes />
        </Suspense>
      </Router>
      {children}
    </>
  );
};

export default Routes;
