// import 'antd/dist/antd.css';
import './App.less';
import 'dotenv/config';
import React from 'react';
import { AppProvider } from './hooks';
import Routes from './routes/index';
import GlobalStyle from './styles/globalStyle';

interface a {
  asd: string;
}

const App: React.FC = () => {
  return (
    <AppProvider>
      <Routes />
      <GlobalStyle />
    </AppProvider>
  );
};

export default App;
