import { FormHandles } from '@unform/core';
import { MessageApi } from 'antd/lib/message';
import 'dotenv/config';
import * as Yup from 'yup';
import { ILawyerInformation } from '../../../../@types/ILawyerInformation';
import { IPlanIdentifierIugu } from '../../../../@types/IPlanIdentifierIugu';
import { Plans, PlansInt } from '../../Lawyers/LawyerPlans/additional/prices';

//  **************************************************************************************

interface DefinePaymentValueProps {
  plan_type: ILawyerInformation['plan_type'];
  paymentInterval: 'monthly' | 'yearly';
}

export function DefinePaymentValue({
  plan_type,
  paymentInterval
}: DefinePaymentValueProps): string {
  if (plan_type === 'partneshipMember') {
    if (paymentInterval === 'monthly') {
      return Plans.price.partneshipMember.monthly;
    }
    if (paymentInterval === 'yearly') {
      return Plans.price.partneshipMember.yearly;
    }
  } else if (plan_type === 'membership') {
    if (paymentInterval === 'monthly') {
      return Plans.price.membership.monthly;
    }
    if (paymentInterval === 'yearly') {
      return Plans.price.membership.yearly;
    }
  } else if (plan_type === 'professional') {
    if (paymentInterval === 'monthly') {
      return Plans.price.professional.monthly;
    }
    if (paymentInterval === 'yearly') {
      return Plans.price.professional.yearly;
    }
  }
  return '';
}

export function DefinePaymentValueByIdentifier(
  identifier: IPlanIdentifierIugu
): number {
  if (identifier === 'partneship_monthly') {
    return PlansInt.price.partneshipMember.monthly;
  } else if (identifier === 'partneship_yearly') {
    return PlansInt.price.partneshipMember.yearly;
  } else if (identifier === 'membership_monthly') {
    return PlansInt.price.membership.monthly;
  } else if (identifier === 'membership_yearly') {
    return PlansInt.price.membership.yearly;
  } else if (identifier === 'professional_monthly') {
    return PlansInt.price.professional.monthly;
  } else if (identifier === 'professional_yearly') {
    return PlansInt.price.professional.yearly;
  }

  return 0;
}

//  **********identifier****************************************************************************

interface validateFieldsProps {
  formRef: React.RefObject<FormHandles>;
  fieldName: 'name' | 'number' | 'cvc' | 'expiry';
  value: string;
  Iugu: any;
}

function resetFormError(formRef: any, fieldName: string) {
  const errors = formRef.current?.getErrors();
  if (errors) {
    delete errors[fieldName];
  }
  formRef.current?.setErrors(errors || {});
}

export async function validateFields({
  formRef,
  fieldName,
  value,
  Iugu
}: validateFieldsProps): Promise<void> {
  if (fieldName === 'expiry') {
    const isValid: boolean = Iugu.utils.validateExpirationString(value);
    resetFormError(formRef, fieldName);
    if (!isValid) {
      formRef.current?.setFieldError('expiry', 'Expiração Inválida');
    }
  }
  if (fieldName === 'cvc') {
    const schema = Yup.object().shape({
      [fieldName]: Yup.string()
        .required('CVV Inválido')
        .min(2, 'CVV Inválido')
        .max(4, 'CVV Inválido')
    });
    const data = {
      [fieldName]: value
    };

    resetFormError(formRef, fieldName);
    try {
      await schema.validate(data);
    } catch (error) {
      formRef.current?.setFieldError(fieldName, 'CVV Inválido');
    }
  }
  if (fieldName === 'name') {
    let isValid: string[] = [];
    if (value) {
      isValid = value.split(' ');
    }

    resetFormError(formRef, fieldName);
    if (isValid.length === 1) {
      formRef.current?.setFieldError(fieldName, 'Insira seu Nome Completo');
    }
  }
  if (fieldName === 'number') {
    const isValid: boolean = Iugu.utils.validateCreditCardNumber(value);
    resetFormError(formRef, 'cardNumber');

    if (!isValid) {
      formRef.current?.setFieldError('cardNumber', 'Cartão Inválido');
    }
  }
}

//  **************************************************************************************

interface InputProps {
  e: any;
  formRef: React.RefObject<FormHandles>;
  SetNumber?(string: React.SetStateAction<string>): void;
  SetName?(string: React.SetStateAction<string>): void;
}

export function SpaceAfterFourDigits({
  SetNumber,
  e,
  formRef
}: InputProps): void {
  const value = e.target.value;

  const onlyNumbers = value.replace(/[^\d]/g, '');

  const a = onlyNumbers
    .replace(/\D/g, '')
    .replace(/^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g, '$1 $2 $3 $4');

  // const a = value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');

  SetNumber && SetNumber(a);

  formRef.current?.setFieldValue('number', a);
}

//  **************************************************************************************

export function toUpperCase({ SetName, e, formRef }: InputProps): void {
  const value: string = e.target.value;

  const a = value.toUpperCase();

  SetName && SetName(a);

  formRef.current?.setFieldValue('name', a);
}

//  **************************************************************************************

export function CorrectPlansName(
  plans:
    | 'partneshipMember'
    | 'starter'
    | 'professional'
    | 'membership'
    | 'partnership'
) {
  if (plans === 'starter') {
    return 'Starter';
  }
  if (plans === 'membership') {
    return 'Membership';
  }
  if (plans === 'partneshipMember') {
    return 'Partneship Member';
  }
  if (plans === 'partnership') {
    return 'Partneship Member';
  }
  if (plans === 'professional') {
    return 'Professional';
  } else {
    window.location.href = 'escritorio/cadastro/planos';
    return 'Starter';
  }
}

// --------------------------------------------------------------------------------------//
//                                                                                      //
//                                       Payment                                        //
//                                                                                      //
// --------------------------------------------------------------------------------------//

interface CreateIuguPaymentTokenProps {
  Iugu: any;
  CreditCardNumber: string;
  ExpiryMonth: string;
  ExpiryYear: string;
  firstName: string;
  lastName: string;
  Cvv: string;
  message: MessageApi;
  callback(token: string): Promise<void>;
  SetLoadingState(state: React.SetStateAction<boolean>): void;
}
export async function CreateIuguPaymentToken({
  Iugu,
  CreditCardNumber,
  Cvv,
  ExpiryMonth,
  ExpiryYear,
  firstName,
  lastName,
  message,
  callback,
  SetLoadingState
}: CreateIuguPaymentTokenProps): Promise<string | void> {
  if (typeof Iugu !== 'undefined') {
    SetLoadingState(true);
    const cc = Iugu.CreditCard(
      CreditCardNumber,
      ExpiryMonth,
      `20${ExpiryYear}`,
      firstName,
      lastName,
      Cvv
    );

    const IsIuguCreditCardValid = cc.valid();

    if (!IsIuguCreditCardValid) {
      SetLoadingState(false);
      return message.error('Cartão Inválido');
    }

    if (IsIuguCreditCardValid) {
      await Iugu.createPaymentToken(cc, async function (response: any) {
        if (response.errors) {
          return message.error(
            'Erro durante o salvamento do cartão, tente novamente'
          );
        } else {
          // message.info('Token criado:' + response.id);

          await callback(response.id);
        }
      });
    }
  } else {
    SetLoadingState(false);
    return message.error('Houve um erro desconhecido');
  }
}

//  **************************************************************************************

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ConfigureIugu(Iugu: any): void {
  if (typeof Iugu !== 'undefined') {
    Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID);
    const IuguTestModeStatus =
      process.env.REACT_APP_IUGU_TEST_MODE === 'true'
        ? true
        : process.env.REACT_APP_IUGU_TEST_MODE === 'false'
        ? false
        : true;
    Iugu.setTestMode(IuguTestModeStatus);
  }
}

//  **************************************************************************************
