import { AxiosResponse } from 'axios';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { ILawyerInformation } from '../@types/ILawyerInformation';
import api from '../services/api';
import { useAuth } from './auth';

interface UserProps {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  type: 'lawyer' | 'user';
  created_at: string;
  updated_at: string;
}

interface AuthState {
  token: string;
  refreshToken: string;
  user: UserProps;
  lawyerData?: ILawyerInformation;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextDataProps {
  updateLawyerData(): Promise<AxiosResponse<ILawyerInformation> | void>;
  setLawyerData(data: ILawyerInformation): void;
  lawyerData: ILawyerInformation;
}

// Todas as Funções ligadas ao authContext é bacana que fiquem centralizadas no arquivo

// Precisamos deixar o objeto vazio para que o auth apenas seja preenchido após login
// este é um pequeno hack para atingir este objetivo
const LawyerDataContext = createContext<AuthContextDataProps>(
  {} as AuthContextDataProps
);

// função de preenchimento de valor inicial do storage data para
// pegar o localstorage se estiver armazenado ou inciar vazio caso contrário
export const LawyerDataProvider: React.FC = ({ children }) => {
  const [lawyerData, setData] = useState<ILawyerInformation>(() => {
    const lawyerData = localStorage.getItem('@DireitoPratico:LawyerData');

    if (lawyerData) {
      return JSON.parse(lawyerData);
    }
    return {} as ILawyerInformation;
  });

  const { user } = useAuth();

  const updateLawyerData = useCallback(async () => {
    try {
      if (user.type === 'lawyer') {
        const response = await api.get<ILawyerInformation>(
          `/lawyer/id/${user.id}`
        );

        const dataL = response.data;

        if (dataL) {
          localStorage.setItem(
            '@DireitoPratico:LawyerData',
            JSON.stringify(response.data)
          );
        }
        return response;
      }
    } catch (error) {}
  }, []);

  const setLawyerData = useCallback((data: ILawyerInformation) => {
    if (data) {
      localStorage.setItem('@DireitoPratico:LawyerData', JSON.stringify(data));
    }
  }, []);

  return (
    //  {/*  O AuthContext.Provider dá a todos os componentes children acesso a informação
    // que ele contem, para inicializa-lo precisamos definir o 'value' */}
    <LawyerDataContext.Provider
      value={{
        updateLawyerData,
        setLawyerData,
        lawyerData
      }}
    >
      {/* Tudo que o AuthProvider receber como filho (componente dentro do
        componente), será repassado para eles. */}
      {children}
    </LawyerDataContext.Provider>
  );
};

// useAuth() criado componentização no arquivo do contexto para evitar o useContext(AuthContext); toda vez

export function useLawyerData(): AuthContextDataProps {
  const context = useContext(LawyerDataContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
