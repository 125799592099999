import React, { createContext, useCallback, useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ILawyerInformation } from '../@types/ILawyerInformation';
import { IUser } from '../@types/IUser';
import api from '../services/api';
import { env } from '../utils/EnviromentVariables';
import { GetUserData } from '../utils/RoutesFunctions/functions.Users';

export interface UserProps {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  type: 'lawyer' | 'user';
  created_at: string;
  updated_at: string;
  active: boolean;
}

interface AuthState {
  token: string;
  refreshToken: string;
  user: UserProps;
  lawyerData?: ILawyerInformation;
}

interface SignInCredentials {
  email: string;
  password: string;
  plans?: boolean;
}

interface AuthContextData {
  user: UserProps;
  token: string;
  refreshToken: string;
  signIn(credentials: SignInCredentials): Promise<React.ReactNode>;
  signOut(): void;
  lawyerData: ILawyerInformation;
  updateUser(): void;
}

// Todas as Funções ligadas ao authContext é bacana que fiquem centralizadas no arquivo

// Precisamos deixar o objeto vazio para que o auth apenas seja preenchido após login
// este é um pequeno hack para atingir este objetivo
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

// função de preenchimento de valor inicial do storage data para
// pegar o localstorage se estiver armazenado ou inciar vazio caso contrário
export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@DireitoPratico:token');
    const user = localStorage.getItem('@DireitoPratico:user') || '{}';
    const lawyerData = localStorage.getItem('@DireitoPratico:LawyerData');
    const refreshToken = localStorage.getItem('@DireitoPratico:refreshToken');

    if (token && user && refreshToken && lawyerData) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {
        token,
        user: JSON.parse(user),
        refreshToken,
        lawyerData: JSON.parse(lawyerData)
      };
    }

    if (token && user && refreshToken && !lawyerData) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user), refreshToken };
    }

    return {} as AuthState;
  });

  async function updateUser() {
    try {
      const { data: user } = await GetUserData(data.user.id);

      localStorage.setItem('@DireitoPratico:user', JSON.stringify(user));
      setData({ ...data, user });
    } catch (error) {
      console.log(error);
    }
  }

  const signIn = useCallback(async ({ email, password, plans }) => {
    const response = await api.post('/session', {
      email,
      password
    });

    const { token, user, refreshToken } = response.data;

    if (token) {
      // armazenamento dos dados no localStorage
      localStorage.setItem('@DireitoPratico:token', token);
      localStorage.setItem('@DireitoPratico:user', JSON.stringify(user));
      localStorage.setItem('@DireitoPratico:refreshToken', refreshToken);

      api.defaults.headers.authorization = `Bearer ${token}`;

      if (user.type === 'lawyer') {
        try {
          const response = await api.get(`/lawyer/id/${user.id}`);

          if (response) {
            localStorage.setItem(
              '@DireitoPratico:LawyerData',
              JSON.stringify(response.data)
            );
            setData({ token, user, refreshToken, lawyerData: response.data });
          }
        } catch (error) {}
      } else {
        setData({ token, user, refreshToken });
      }
    } else {
      throw new Error('Invalid Login Credencial');
    }

    return (user as IUser).active ? (
      user.type === 'lawyer' ? (
        plans ? (
          (window.location.href = env.app_dashboard)
        ) : (
          <Redirect to="/escritorio" />
        )
      ) : (
        user.type === 'user' && <Redirect to="/painel" />
      )
    ) : (
      (window.location.href = '/verify_email')
    );
  }, []);

  // removendo todos os dados previamente armazenados de login
  const signOut = useCallback(async () => {
    window.location.href = '/';
    localStorage.removeItem('@DireitoPratico:token');
    localStorage.removeItem('@DireitoPratico:user');
    localStorage.removeItem('@DireitoPratico:refreshToken');
    localStorage.removeItem('@DireitoPratico:LawyerData');
    localStorage.removeItem('@DireitoPratico:Contacts');
    window.location.href = env.app_dashboard + '/redirection/signout';
    setData({} as AuthState);
  }, []);

  return (
    //  {/*  O AuthContext.Provider dá a todos os componentes children acesso a informação
    // que ele contem, para inicializa-lo precisamos definir o 'value' */}
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        token: data.token,
        refreshToken: data.refreshToken,
        lawyerData: data.lawyerData
          ? data.lawyerData
          : ({} as ILawyerInformation),
        updateUser: updateUser
      }}
    >
      {/* Tudo que o AuthProvider receber como filho (componente dentro do
        componente), será repassado para eles. */}
      {children}
    </AuthContext.Provider>
  );
};

// useAuth() criado componentização no arquivo do contexto para evitar o useContext(AuthContext); toda vez

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
