import axios from 'axios';
import { has } from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';
import { ISubscriptionsCurrent } from '../@types/ISubscriptions';
import { WalletService } from '../services/GatewayServices/WalletService';

import { SubscriptionService } from '../services/Subscriptions/SubscriptionService';
import { useAuth } from './auth';
import { IWalletModel } from './Wallet';

interface SubscriptionProps {
  data: ISubscriptionsCurrent | null;
  isSubscribed: boolean;
  wallet: {
    hasWallet: boolean;
    walletData: IWalletModel | null;
  };
}

interface SubscriptionContextProps {
  subscription: SubscriptionProps;
  updateSubscription: () => void;
}

const SubscriptionContext = createContext<SubscriptionContextProps>(
  {} as SubscriptionContextProps
);

export const SubscriptionProvider: React.FC = ({ children }) => {
  const auth = useAuth();

  const noWallet = {
    hasWallet: false,
    walletData: null
  };

  // const [subscription, setSubscription] = usePersistedState<SubscriptionProps>(
  //   'direito-pratico@subscription',
  //   {
  //     data: null,
  //     isSubscribed: false,
  //     wallet: noWallet
  //   }
  // );

  const [
    SubscriptionState,
    SetSubscriptionState
  ] = React.useState<SubscriptionProps>({
    data: null,
    isSubscribed: false,
    wallet: noWallet
  });

  async function GetSubscription() {
    if (has(auth.user, 'id')) {
      const service = new SubscriptionService();
      const walletService = new WalletService();
      try {
        const subData = localStorage.getItem('direito-pratico@subscription');

        if (subData) {
          const sub = JSON.parse(subData);
          if (sub) {
            SetSubscriptionState(sub);
          }
        }

        const { data: sub } = await service.GetUserSubscription(auth.user.id);
        console.log('SUBSCRIPTION', sub);

        let wallet: any = null;
        let hasWallet = false;
        try {
          const { data: walletData } = await walletService.GetUserWallets();
          wallet = walletData.data[0];
          hasWallet = walletData.totalCount === 0 ? false : true;
        } catch (error) {
          hasWallet = false;
          console.log('user doesnt has wallet');
        }

        SetSubscriptionState({
          data: sub,
          isSubscribed: true,
          wallet: hasWallet ? { hasWallet, walletData: wallet } : noWallet
        });
        const par = JSON.stringify({
          data: sub,
          isSubscribed: true,
          wallet: hasWallet ? { hasWallet, walletData: wallet } : noWallet
        });
        localStorage.setItem('direito-pratico@subscription', par);
      } catch (error) {
        console.log(error);
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            console.log('error 404');
            SetSubscriptionState({
              data: null,
              isSubscribed: false,
              wallet: noWallet
            });
            const par = JSON.stringify({
              data: null,
              isSubscribed: false,
              wallet: noWallet
            });
            localStorage.setItem('direito-pratico@subscription', par);
          }
        }
      }
    }
  }

  useEffect(() => {
    GetSubscription();
  }, [has(auth.user, 'id')]);

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       Return                                         //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  return (
    <SubscriptionContext.Provider
      value={{
        subscription: SubscriptionState,
        updateSubscription: GetSubscription
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export function useSubscription(): SubscriptionContextProps {
  const context = useContext(SubscriptionContext);

  if (!context) {
    throw new Error('useSubscription must be used within an AuthProvider');
  }
  return context;
}
