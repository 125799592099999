import moment from 'moment';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { INotifications } from '../@types/INotifications';
import ConferenceCallingScreen from '../components/conferenceCallingScreen/conferenceCallingScreen';
import Backend from '../config/backendAdress';
import { ChatUserProps } from '../pages/private/Shared/Chat/Chat';
import appSocket from '../services/webSocket';
import { GetNotifications } from '../utils/RoutesFunctions/functions.Notifications';
import { useAuth } from './auth';
import { useToast } from './Toast';

interface NotificationCreation {
  users: string[];
  id: string;
  title: string;
  content: string;
  type?: 'NewContact' | 'default';
  tag: string;
  visualized: boolean;
  action_state: boolean;
  link: string;
  created_at: string;
  updated_at: string;
}

interface NotificationContextProps {
  notifications: INotifications[];
  setNotifications(notifications: any): void;
  CreateNotification(data: NotificationCreation): void;
}

const NotificationsDataContext = createContext<NotificationContextProps>(
  {} as NotificationContextProps
);

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<INotifications[]>(
    [] as INotifications[]
  );

  const { user } = useAuth();

  const { addToast } = useToast();

  const NotificationSound = useMemo(
    () => new Audio(`${Backend.files}/${'system/notification-sound.mp3'}`),
    []
  );

  useEffect(() => {
    appSocket.on('receivedNotification', (notification: INotifications[]) => {
      setNotifications(n => [...n, ...notification]);

      NotificationSound.play();
    });
  }, []);

  useEffect(() => {
    if (user !== undefined) {
      GetNotifications(user.id).then(res => {
        if (res) {
          const responseData = res.data;
          responseData.sort((a, b) =>
            moment(moment(b.created_at).unix()).diff(
              moment(a.created_at).unix()
            )
          );
          setNotifications(n => [...n, ...responseData]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function CreateNotification(notification: NotificationCreation) {
    appSocket.emit('createNotification', notification);
    addToast({
      title: 'Notificações enviadas para os usuários',
      type: 'success'
    });
  }
  const [ShowCallingState, SetShowCallingState] = useState(false);
  const [Caller, SetCaller] = useState<ChatUserProps>({} as ChatUserProps);
  const [ConferenceLink, SetConferenceLink] = useState('');

  interface ConferenceData {
    caller: ChatUserProps;
    ConferenceLink: string;
  }

  function NewVideoConferenceCall(caller: ChatUserProps, link: string) {
    SetCaller(caller);
    SetConferenceLink(link);
    SetShowCallingState(true);
    setTimeout(() => {
      SetShowCallingState(false);
    }, 60500);
  }

  useEffect(() => {
    appSocket.on(
      'receiveCallConference',
      ({ ConferenceLink, caller }: ConferenceData) => {
        NewVideoConferenceCall(caller, ConferenceLink);
      }
    );
  }, []);

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       Return                                         //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  return (
    <NotificationsDataContext.Provider
      value={{
        notifications,
        setNotifications,
        CreateNotification
      }}
    >
      {ShowCallingState && (
        <ConferenceCallingScreen
          SetShowCallingState={SetShowCallingState}
          ShowCallingState={ShowCallingState}
          caller={Caller}
          link={ConferenceLink}
        />
      )}

      {children}
    </NotificationsDataContext.Provider>
  );
};

export function useNotifications(): NotificationContextProps {
  const context = useContext(NotificationsDataContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
