import { has } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { IAgendaEvents } from '../@types/IAgendaEvents';
import { IAvatars } from '../@types/IAvatars';
import { ILawyerInformation } from '../@types/ILawyerInformation';
import { IPlanIdentifierIugu } from '../@types/IPlanIdentifierIugu';
import Backend from '../config/backendAdress';
import api from '../services/api';
import { GetUserCalendarEvents } from '../utils/RoutesFunctions/functions.calendar';
import { useAuth } from './auth';

export interface AvatarProps extends IAvatars {
  link: string;
}

interface LawyerSelectedPlan {
  planIdentifier: IPlanIdentifierIugu;
  simplePlan: Exclude<ILawyerInformation['plan_type'], undefined>;
}

interface UtilsContextProps {
  ShowCreateRecordDrawer: boolean;
  SetShowCreateRecordDrawer(state: boolean): void;

  HighlightChatIdSelected: string;
  SetHighlightChatIdSelected(id: string): void;

  ShowCreateEventDrawer: boolean;
  SetShowCreateEventDrawer(state: boolean): void;

  UserAvatar: AvatarProps;
  SetUserAvatar(AvatarObj: AvatarProps): void;

  EventDetails: IAgendaEvents;
  SetEventDetails(AvatarObj: IAgendaEvents): void;

  Events: IAgendaEvents[];
  SetEvents(events: React.SetStateAction<IAgendaEvents[]>): void;

  EditState: boolean;
  SetEditState(state: React.SetStateAction<boolean>): void;

  EditEventDetails: IAgendaEvents;
  SetEditEventDetails(event: React.SetStateAction<IAgendaEvents>): void;

  SetShowProfileInformationDrawerState(
    state: React.SetStateAction<boolean>
  ): void;
  ShowProfileInformationDrawerState: boolean;

  LawyerPlanSelectedOnSelectPlan: LawyerSelectedPlan;
  SetLawyerPlanSelectedOnSelectPlan(
    info: React.SetStateAction<LawyerSelectedPlan>
  ): void;
}

const UtilsContext = createContext<UtilsContextProps>({} as UtilsContextProps);

export const UtilsProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [HighlightChatIdSelected, SetHighlightChatIdSelected] = useState('');

  const [ShowCreateRecordDrawer, SetShowCreateRecordDrawer] = useState(false);
  const [ShowCreateEventDrawer, SetShowCreateEventDrawer] = useState(false);

  const [
    ShowProfileInformationDrawerState,
    SetShowProfileInformationDrawerState
  ] = useState(false);

  const [EventDetails, SetEventDetails] = useState<IAgendaEvents>(
    {} as IAgendaEvents
  );

  const [UserAvatar, SetUserAvatar] = useState<AvatarProps>({} as AvatarProps);
  const [Events, SetEvents] = useState<IAgendaEvents[]>([] as IAgendaEvents[]);
  const [EditState, SetEditState] = useState(false);

  const [EditEventDetails, SetEditEventDetails] = useState<IAgendaEvents>(
    {} as IAgendaEvents
  );

  const [
    LawyerPlanSelectedOnSelectPlan,
    SetLawyerPlanSelectedOnSelectPlan
  ] = useState<LawyerSelectedPlan>({
    planIdentifier: 'membership_monthly',
    simplePlan: 'membership'
  } as LawyerSelectedPlan);

  useEffect(() => {
    if (has(user, 'id')) {
      api.get<IAvatars>(`/avatar/id/${user.id}`).then(response => {
        if (response) {
          SetUserAvatar({
            ...response.data,
            link: `${Backend.files}/${response.data.avatar}`
          });
        }
      });
      GetUserCalendarEvents(user.id).then(res => {
        if (res) {
          const Processed = res.data.map((item: any) => {
            item.start = new Date(item.start);
            item.end = new Date(item.start);
            return item;
          });
          SetEvents(Processed);
        }
      });
    }
  }, [user]);

  const { url } = useRouteMatch();

  // useEffect(() => {
  //   SetHighlightChatIdSelected('');
  // }, [url]);

  // ---- Get lawyer information -----------------------------------------------------------

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       Return                                         //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  return (
    <UtilsContext.Provider
      value={{
        ShowCreateRecordDrawer,
        SetShowCreateRecordDrawer,
        ShowCreateEventDrawer,
        SetShowCreateEventDrawer,
        UserAvatar,
        SetUserAvatar,
        SetHighlightChatIdSelected,
        HighlightChatIdSelected,
        SetEventDetails,
        EventDetails,
        SetEvents,
        Events,
        EditState,
        SetEditState,
        SetEditEventDetails,
        EditEventDetails,
        SetShowProfileInformationDrawerState,
        ShowProfileInformationDrawerState,
        LawyerPlanSelectedOnSelectPlan,
        SetLawyerPlanSelectedOnSelectPlan
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
};

export function useUtils(): UtilsContextProps {
  const context = useContext(UtilsContext);

  if (!context) {
    throw new Error('useUtils must be used within an AuthProvider');
  }
  return context;
}
