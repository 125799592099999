import styled from 'styled-components';

export const Container = styled.div`
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .center-full {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .vertical {
    flex-direction: column;
  }
  height: auto;
`;

export const AttendUp = styled.div`
  padding: 1rem;
  border: 2px solid ${props => props.theme.colors.online};
  background: ${props => props.theme.colors.online};

  cursor: pointer;

  border-radius: 1rem;

  transition: all 0.2s ease-in-out;

  svg {
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    background: ${props => props.theme.colors.whiteOrDark};
    svg {
      color: ${props => props.theme.colors.darkOrWhite};
    }
  }
`;

export const AttendOff = styled.div`
  padding: 1rem;
  background: #bd1309;
  border: 2px solid #bd1309;
  cursor: pointer;

  border-radius: 1rem;

  transition: all 0.2s ease-in-out;

  svg {
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    background: ${props => props.theme.colors.whiteOrDark};
    svg {
      color: ${props => props.theme.colors.darkOrWhite};
    }
  }
`;
