import { ValidationError } from 'yup';

interface Errors {
  // forma de passar qualquer valor como chave ao invés de um valor fixo
  // já que poderá existir várias chaves de erro
  [key: string]: string;
}

export default function getValidationErrors(errors: ValidationError): Errors {
  const ValidationErrors: Errors = {};

  errors.inner.forEach(error => {
    const path: any = error.path;
    ValidationErrors[path] = error.message;
  });
  return ValidationErrors;
}
