import { Player } from '@lottiefiles/react-lottie-player';
import { Modal, Row, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import success from '../../../../../assets/additional/success.json';
import { StyledButton } from '../../../../../components/SuperButton/styles';
import { CenterFull } from '../../../Lawyers/LawyerPlans/Components/subcomponents/SelectCreditCardPaymentMethodsModal';
import { ChatUserProps } from '../../Chat/Chat';
const { Title, Text, Paragraph } = Typography;

interface SenderNewVideoMeetingModalProps {
  SetIsVideoMeetingModalVisible(state: React.SetStateAction<boolean>): void;
  IsVideoMeetingModalVisible: boolean;
  onCancel?(): void;
  ParticipantData: ChatUserProps;
  ParticipantLink: string;
  UserLink: string;
}

export const LinkArea = styled.div`
  .title {
  }
  .link {
    padding: 0.5rem 0.7rem;
    border-radius: 1.2rem;
    background: rgba(220, 220, 220, 0.4);

    width: 100%;

    transition: all 0.2s ease-in-out;
    &:hover {
      background: rgba(220, 220, 220, 0.2);
    }
  }
`;

const SenderNewVideoMeetingModal: React.FC<SenderNewVideoMeetingModalProps> = ({
  IsVideoMeetingModalVisible,
  SetIsVideoMeetingModalVisible,
  onCancel,
  ParticipantData,
  ParticipantLink,
  UserLink
}) => {
  if (!ParticipantData) {
    return <></>;
  }
  return (
    <>
      <Modal
        title="Nova Reunião"
        visible={IsVideoMeetingModalVisible}
        onCancel={() => {
          SetIsVideoMeetingModalVisible(false);
          onCancel && onCancel();
        }}
        zIndex={1000001}
        centered
        destroyOnClose
        footer={
          <>
            <Space align="center" direction="vertical">
              <StyledButton
                onClick={() => {
                  SetIsVideoMeetingModalVisible(false);
                }}
              >
                Ok
              </StyledButton>
            </Space>
          </>
        }
      >
        <CenterFull style={{ flexDirection: 'column' }}>
          <Title level={2} style={{ textAlign: 'center' }}>
            Reunião Online Iniciada!
          </Title>

          <Player
            autoplay
            loop
            style={{
              width: '150px',
              height: '150px',
              objectFit: 'cover',
              padding: '2rem'
            }}
            background="transparent"
            src={success}
          />
          <Space direction="vertical" size="large">
            <Text style={{ textAlign: 'center' }}>
              Uma solicitação para entrar na reunião foi enviada para{' '}
              {`${ParticipantData.first_name} ${ParticipantData.last_name}`},{' '}
              <br />
              mas você também pode direcionar o link da reunião manualmente.
            </Text>
            <Space direction="vertical" style={{ maxWidth: '30rem' }}>
              <LinkArea>
                <Title level={5} className="title">
                  Seu Link Pessoal
                </Title>
                <Paragraph
                  className="link"
                  ellipsis={{
                    rows: 1
                  }}
                  copyable={{
                    tooltips: ['Copiar', 'Copiado!']
                  }}
                >
                  {UserLink}
                </Paragraph>
              </LinkArea>
              <LinkArea>
                <Title level={5} className="title">
                  Link do Participante
                </Title>
                <Paragraph
                  className="link"
                  copyable={{
                    tooltips: ['Copiar', 'Copiado!']
                  }}
                  ellipsis={{
                    rows: 1
                  }}
                >
                  {ParticipantLink}
                </Paragraph>
              </LinkArea>
            </Space>
          </Space>
        </CenterFull>
      </Modal>
    </>
  );
};

export default SenderNewVideoMeetingModal;
