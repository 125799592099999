import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip/Tooltip.Index';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isColumned: boolean;
  isTitle: boolean;
  labelDefaultVisible?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  position: relative;
  align-items: center;

  background: ${props => props.theme.colors.whiteOrDark};

  border-radius: 0rem;

  color: ${props => props.theme.colors.text};
  border: none;
  padding-bottom: 0.2rem;
  border-bottom: 2px solid ${props => props.theme.colors.lightGrey};
  transition: all 0.2s ease-in-out;

  width: 100%;

  ${props =>
    props.isColumned
      ? css`
          margin-bottom: 1.3rem;
        `
      : css``};
  & + div {
    ${props =>
      props.isColumned
        ? css`
            margin-left: 1rem;
            margin-bottom: 1.3rem;
          `
        : css`
            margin-top: 1rem;
          `};
  }
  svg {
    color: ${props => props.theme.colors.DarkOrWhiteIcon};
    margin-right: 16px;
  }
  ${props =>
    props.isErrored &&
    css`
      svg {
        color: ${props => props.theme.colors.primary};
      }
      border-bottom: 2px solid red;
    `}
  ${props =>
    props.isFocused &&
    css`
      svg {
        color: ${props => props.theme.colors.primary};
      }
      border-color: ${props => props.theme.colors.primary};
    `}
  ${props =>
    props.isFilled &&
    css`
      svg {
        color: ${props => props.theme.colors.primary};
      }
    `}

  input {
    position: relative;
    ${props =>
      props.isTitle
        ? css`
            font-size: 1.3rem;
            font-weight: 500;
          `
        : css`
            font-size: 1rem;
            font-weight: 400;
          `}

    color: ${props => props.theme.colors.text};
    border: 0;
    flex: 1;
    background: transparent;

    /* todo input que for precedido de outro */
    & + input {
      margin-top: 8px;
    }
  }
`;

export const Errors = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const Error = styled.div<ContainerProps>``;

export const Label = styled.span<ContainerProps>`
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0);

  border-left: 0.5rem solid rgba(255, 255, 255, 0);
  border-right: 0.5rem solid rgba(255, 255, 255, 0);

  position: absolute;
  left: -0.5rem;

  ${props =>
    props.isTitle
      ? css`
          bottom: 1.7rem;
          font-weight: 600;
        `
      : css`
          bottom: 1.4rem;
          font-weight: 500;
        `}
  ${props =>
    props.labelDefaultVisible
      ? css`
          color: ${props => props.theme.colors.lightText};
        `
      : css`
          color: rgba(255, 255, 255, 0);
        `}

  font-size: 0.75rem;

  ${props =>
    props.isFilled &&
    css`
      color: ${props => props.theme.colors.lightText};
      //background: ${props => props.theme.colors.whiteOrDark};

      border-left: 0.5rem solid ${props => props.theme.colors.whiteOrDark};
      border-right: 0.5rem solid ${props => props.theme.colors.whiteOrDark};
    `}
`;
