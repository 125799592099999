/* eslint-disable react/no-children-prop */
/* eslint-disable react/display-name */
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '../components/Loading/Loading';
import { CPSidebar } from '../components/Sidebar';

const NewContact = lazy(
  () => import('../pages/public/Contact/NewContactPage/Contact')
);

const PartnershipRequestSuccess = lazy(
  () =>
    import(
      '../pages/private/Lawyers/LawyerSignUp/Sucess/PartneshipMemberApplicationSuccess'
    )
);
const DigitalAssistantPromotionalPage = lazy(
  () =>
    import(
      '../pages/public/DigitalAssistantPromotionalPage/DigitalAssistantPromotionalPage.index'
    )
);
const ConciliationPage = lazy(
  () => import('../pages/public/ConciliationPage/ConciliationPage')
);
const ConciliationFormSendedSuccess = lazy(
  () =>
    import(
      '../pages/public/ConciliationForm/subPages/success/ConciliationFormSendedSuccess.index'
    )
);

const ConciliationFormPage = lazy(
  () => import('../pages/public/ConciliationForm/conciliationForm.index')
);
const DigitalAssistantPage = lazy(
  () => import('../pages/public/DigitalAssistant')
);

const PartneshipMemberLandingPage = lazy(
  () =>
    import(
      '../pages/private/Lawyers/PartneshipLandingPage/PartneshipLandingPage'
    )
);
const BlogIframe = lazy(() => import('../pages/public/BlogIframe/BlogIframe'));

const OfficeProfile = lazy(
  () => import('../pages/public/OfficeProfile/OfficeProfile')
);

const LGPD = lazy(() => import('../pages/public/OtherPages/LGPD/LGPD'));
const PartneshipMemberContract = lazy(
  () =>
    import(
      '../pages/public/OtherPages/PartneshipMemberContract/PartneshipMemberContract'
    )
);
const PrivacyPolicy = lazy(
  () => import('../pages/public/OtherPages/PrivacityPolicy/PrivacyPolicy')
);
const UserTerms = lazy(
  () => import('../pages/public/OtherPages/UserTerms/userTerms')
);
const ValidateAccountCP = lazy(
  () => import('../pages/public/ValidateAccount/ValidateAccount')
);

const SetNewPassword = lazy(
  () => import('../pages/public/SignIn/setNewPassword/SetNewPassword')
);
const RecoveryEmailSent = lazy(
  () => import('../pages/public/SignIn/passwordReset/Sucess/RecoveryEmailSent')
);
const SingleContract = lazy(
  () => import('../pages/public/Contracts/SingleContract')
);
const UsersSignUpInformationVerify = lazy(
  () => import('../pages/public/SignUp/Sucess/UserSignUpInformationSucess')
);
const Contact = lazy(() => import('../pages/public/Contact/Contact'));
const HomePage = lazy(() => import('../pages/public/HomePage/homepage'));
const PGSignIn = lazy(() => import('../pages/public/SignIn/signIn'));
const PGLawersSearchResult = lazy(
  () => import('../pages/public/LawyersSearchResult/lawyersSearchResult')
);
const LawyerProfile = lazy(
  () => import('../pages/public/LawyerProfile/lawyerProfile')
);
const SignUpChooseUserType = lazy(
  () => import('../pages/public/SignUp/ChooseUserType/ChooseUserType')
);

const CreateUser = lazy(
  () => import('../pages/public/SignUp/CreateUser/CreateUser')
);

const SpecialtiesAndOccupationArea = lazy(
  () =>
    import(
      '../pages/public/SpecialtiesAndOccuppationAreas/SpecialtiesAndOccupationAreas.index'
    )
);
const PageNotFound = lazy(
  () => import('../pages/public/NotFoundError/PageNotFound')
);

const LawyerPlans = lazy(
  () => import('../pages/private/Lawyers/LawyerPlans/index.SignUpLawyerPlans')
);

// --------------------------------------------------------------------------------------//
//                                                                                      //
//                                        Routes                                        //
//                                                                                      //
// --------------------------------------------------------------------------------------//

const PublicRoutesArray = [
  {
    path: '/',
    exact: true,
    sidebar: () => <CPSidebar />,
    main: () => <HomePage />
  },
  {
    path: '/resultados/:query',
    sidebar: () => <CPSidebar />,
    main: () => <PGLawersSearchResult />
  },
  {
    path: '/escritorio-digital-para-advogados',
    sidebar: () => <CPSidebar />,
    main: () => <></>
  },
  {
    path: '/partnership',
    sidebar: () => <></>,
    main: () => <PartneshipMemberLandingPage />,
    exact: true
  },
  {
    path: '/partnership/success',
    sidebar: () => <></>,
    main: () => <PartnershipRequestSuccess />
  },

  {
    path: '/validate/account/:userId/:key',
    sidebar: () => <></>,
    main: () => <ValidateAccountCP />
  },
  {
    path: '/materias',
    sidebar: () => <CPSidebar />,
    main: () => <BlogIframe />
  },
  {
    path: '/perfil/:url',
    sidebar: () => <CPSidebar />,
    main: () => <LawyerProfile />
  },
  {
    path: '/perfil/escritorio/:url',
    sidebar: () => <CPSidebar />,
    main: () => <OfficeProfile />
  },
  {
    path: '/especialidades',
    sidebar: () => <CPSidebar />,
    main: () => <SpecialtiesAndOccupationArea />
  },
  // {
  //   path: '/materia/:post',
  //   sidebar: () => <CPSidebar />,
  //   main: () => <Post />
  // },
  {
    path: '/contatos',
    sidebar: () => <CPSidebar />,
    main: () => <PGLawersSearchResult />
  },
  {
    path: '/assistente',
    sidebar: () => <CPSidebar />,
    main: () => <DigitalAssistantPromotionalPage />,
    exact: true
  },
  {
    path: '/assistente/sessao',
    sidebar: () => <CPSidebar />,
    main: () => <DigitalAssistantPage />
  },
  {
    path: '/advogados/planos',
    sidebar: () => <></>,
    main: () => <LawyerPlans />
  },
  {
    path: '/contatos/:name',
    sidebar: () => <CPSidebar />,
    main: () => <SingleContract />
  },
  {
    path: '/404',
    sidebar: () => <></>,
    main: () => <PageNotFound />
  },
  {
    path: '/login',
    sidebar: () => <></>,
    main: () => <PGSignIn />
  },
  {
    path: '/cadastro',
    exact: true,
    sidebar: () => <></>,
    main: () => <SignUpChooseUserType />
  },
  {
    path: '/cadastro/usuario/:type',
    sidebar: () => <></>,
    main: () => <CreateUser />
  },
  {
    path: '/cadastro/verify',
    sidebar: () => <></>,
    main: () => <UsersSignUpInformationVerify />
  },
  {
    path: '/recovery/sent',
    sidebar: () => <></>,
    main: () => <RecoveryEmailSent />
  },
  {
    path: '/contato',
    sidebar: () => <CPSidebar />,
    main: () => <NewContact />
  },
  {
    path: '/recovery/auth/:validation/:email',
    sidebar: () => <></>,
    main: () => <SetNewPassword />
  },

  // law

  {
    path: '/politica_de_privacidade',
    sidebar: () => <CPSidebar />,
    main: () => <PrivacyPolicy />
  },
  {
    path: '/termos_de_uso',
    sidebar: () => <CPSidebar />,
    main: () => <UserTerms />
  },
  {
    path: '/lgpd',
    sidebar: () => <CPSidebar />,
    main: () => <LGPD />
  },
  {
    path: '/partneship_contrato',
    sidebar: () => <CPSidebar />,
    main: () => <PartneshipMemberContract />
  },
  {
    path: '/conciliacao',
    sidebar: () => <CPSidebar />,
    main: () => <ConciliationPage />,
    exact: true
  },
  {
    path: '/conciliacao/formulario',
    sidebar: () => <CPSidebar />,
    main: () => <ConciliationFormPage />,
    exact: true
  },
  {
    path: '/conciliacao/formulario/sucesso',
    sidebar: () => <CPSidebar />,
    main: () => <ConciliationFormSendedSuccess />
  }
];

const PublicRoutes: React.FC = () => {
  return (
    <>
      <Switch>
        {PublicRoutesArray.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={<route.sidebar />}
          />
        ))}
      </Switch>
      <Suspense
        fallback={
          <>
            <Loading loading={true} fullPage>
              <div
                style={{
                  height: '100vh',
                  width: '100vw'
                }}
              ></div>
            </Loading>
          </>
        }
      >
        <Switch>
          {PublicRoutesArray.map((route, index) => (
            // Render more <Route>s with the same paths as
            // above, but different components this time.
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.main />}
            />
          ))}
        </Switch>
      </Suspense>
    </>
  );
};

export default PublicRoutes;
