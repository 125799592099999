import { ConfigProvider } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect } from 'react';
import '../services/api';
import { InitializeGoogleAnalytics } from '../utils/Ads/GoogleAnalytics';
import { AuthProvider } from './auth';
import { ContactsProvider } from './Contacts';
import { LawyerDataProvider } from './lawyerData';
import { SearchLawyerProvider } from './LawyersSearch';
import { MessagesDataProvider } from './Messages';
import { NotificationsProvider } from './Notifications';
import GoogleProvider from './ReCaptcha';
import { RecordDataProvider } from './recordData';
import { ResponsivenessProvider } from './Responsiveness';
import { SideMenuExpandedContextProvider } from './SideMenuStatus';
import { SubscriptionProvider } from './Subscription';
import { ThemeProvider } from './Theme';
import { ToastProvider } from './Toast';
import { UtilsProvider } from './Utils';

moment.locale('pt-br');

// eslint-disable-next-line react/prop-types
export const AppProvider: React.FC = ({ children }) => {
  useEffect(() => {
    InitializeGoogleAnalytics();
  }, []);
  return (
    <ResponsivenessProvider>
      <GoogleProvider>
        <AuthProvider>
          <ThemeProvider>
            <ToastProvider>
              <SearchLawyerProvider>
                <LawyerDataProvider>
                  <ConfigProvider locale={pt_BR}>
                    <SideMenuExpandedContextProvider>
                      <SubscriptionProvider>{children}</SubscriptionProvider>
                    </SideMenuExpandedContextProvider>
                  </ConfigProvider>
                </LawyerDataProvider>
              </SearchLawyerProvider>
            </ToastProvider>
          </ThemeProvider>
        </AuthProvider>
      </GoogleProvider>
    </ResponsivenessProvider>
  );
};

const PrivateProvider: React.FC = ({ children }) => {
  return (
    <NotificationsProvider>
      <ContactsProvider>
        <RecordDataProvider>
          <MessagesDataProvider>
            <UtilsProvider>{children}</UtilsProvider>
          </MessagesDataProvider>
        </RecordDataProvider>
      </ContactsProvider>
    </NotificationsProvider>
  );
};

export default PrivateProvider;
