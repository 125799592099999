// ---- Module Import --------------------------------------------------------------------

import React, { useEffect, useState } from 'react';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { BiSupport } from 'react-icons/bi';
import { BsFilePost } from 'react-icons/bs';
import { FaHandshake, FaUserCircle } from 'react-icons/fa';
import { FiGitPullRequest } from 'react-icons/fi';
import { GoSignIn } from 'react-icons/go';
import { useTransition } from 'react-spring';
// ---- Icons Importing ------------------------------------------------------------------
import { IoIosPeople, IoMdClose, IoMdMenu } from 'react-icons/io';
import { SiGooglemessages } from 'react-icons/si';
import { Link, useRouteMatch } from 'react-router-dom';
import { UseSideMenuStatus } from '../../../hooks/SideMenuStatus';
import usePersistedState from '../../../utils/usePersistedState';
// ---- Content Importing --------------------------------------------------------------------
import LogoDPIcon from '../../../assets/logos/DPlogoWhiteIcon.svg';
import LogoDPPNG from '../../../assets/logos/logo_dp.png';
import { useResponsiveness } from '../../../hooks/Responsiveness';
import { handleBackToTop } from '../../../utils/ScroolToTop';
import {
  ButtonSignIn,
  CollapsedSidebarMenu,
  CollapseMenu,
  GeralMenu,
  HeaderMobile,
  LogoDP,
  LogoDPExtended,
  Menu,
  MenuItens,
  MenuMobile,
  SignInAndUp,
  StyledLink
} from './styled.colapsedSidebar';

interface SidebarProps {
  mobile?: boolean;
}

// --------------------------------------------------------------------------------------//
//                                                                                      //
//                                Capsed Sidebar Section                                //
//                                                                                      //
// --------------------------------------------------------------------------------------//

// ---- Component Section ----------------------------------------------------------------

const CollapsedSidebar: React.FC<SidebarProps> = ({ mobile }) => {
  // ---- Hooks ----------------------------------------------------------------------------

  const [extendedMenu, setExtendedMenu] = usePersistedState(
    'menuExpanded',
    false
  );

  const { setMenuExtended, isExtended } = UseSideMenuStatus();

  const {
    ScreenMD,
    ShowMenuExpandedOnMobileState,
    setShowMenuExpandedOnMobileState
  } = useResponsiveness();
  // ---- Functions ------------------------------------------------------------------------
  function handleExtendMenuState() {
    setExtendedMenu(!extendedMenu);
  }

  function handleCloseMenuOnClickMobile(): void {
    ScreenMD && setShowMenuExpandedOnMobileState(false);
  }
  // ---- Component -----------------------------------------------------------------------
  const IconsSize = 20;

  const [PageName, SetPageName] = useState('empty');
  const { url } = useRouteMatch();

  useEffect(() => {
    const namePage = url.split('/').find(item => item !== '') || '';
    SetPageName(namePage);
  }, [url]);

  const styled = {
    soon: (
      <>
        {!isExtended && (
          <>
            <section
              style={{
                fontSize: '0.7rem',
                paddingTop: '0.4rem',
                marginLeft: '0.3rem',
                fontWeight: 600
              }}
            >
              Em breve
            </section>
          </>
        )}
      </>
    )
  };

  const VisibleOnCollapse = useTransition(!extendedMenu, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  //   const VisibleOnExtended = useTransition(extendedMenu, null, {
  //     from: { opacity: 0 },
  //     enter: { opacity: 1 },
  //     leave: { opacity: 0 },
  // from
  //     config: {
  //       duration: 200,

  //     }
  //   });

  return (
    <GeralMenu>
      <CollapsedSidebarMenu extendedMenu={extendedMenu}>
        <HeaderMobile>
          <MenuMobile
            onClick={() => {
              handleExtendMenuState();
              setMenuExtended();
            }}
          >
            <IoMdMenu size={IconsSize} />
          </MenuMobile>
          <Link to="/">
            <LogoDP extendedMenu={extendedMenu}>
              <img src={LogoDPIcon} alt="Logo DP" />
            </LogoDP>
            <LogoDPExtended extendedMenu={extendedMenu}>
              <img src={LogoDPPNG} alt="Logo DP" />
            </LogoDPExtended>
          </Link>
        </HeaderMobile>
        <Menu>
          <MenuItens>
            {extendedMenu ? (
              <CollapseMenu>
                <button
                  onClick={() => {
                    !ScreenMD && handleExtendMenuState();
                    !ScreenMD && setMenuExtended();
                    ScreenMD && setShowMenuExpandedOnMobileState(false);
                  }}
                >
                  {ScreenMD ? (
                    <IoMdClose size={IconsSize} />
                  ) : (
                    <AiOutlineMenuFold size={IconsSize} />
                  )}
                  <span className="script">Encolher Menu</span>
                </button>
              </CollapseMenu>
            ) : (
              <StyledLink
                onClick={() => {
                  handleExtendMenuState();
                  setMenuExtended();
                }}
                extendedMenu={extendedMenu}
                to="#"
                active={false}
              >
                <button>
                  {extendedMenu ? '' : ''}
                  <AiOutlineMenuUnfold size={IconsSize} />
                  <div>{extendedMenu ? '' : 'Expandir Menu'}</div>
                </button>
              </StyledLink>
            )}

            <StyledLink
              active={!PageName}
              extendedMenu={extendedMenu}
              to="/"
              onClick={() => {
                handleCloseMenuOnClickMobile();
                handleBackToTop();
              }}
            >
              <IoIosPeople size={IconsSize} />

              <div>Encontre Advogados</div>
            </StyledLink>

            <StyledLink
              active={PageName === 'especialidades'}
              extendedMenu={extendedMenu}
              to="/especialidades"
              onClick={() => {
                handleCloseMenuOnClickMobile();
              }}
            >
              <FiGitPullRequest size={IconsSize} />
              <div>
                {extendedMenu ? (
                  <>
                    Áreas de Atuação/
                    <br />
                    Especialidades
                  </>
                ) : (
                  <>Áreas de Atuação/Especialidades</>
                )}
              </div>
            </StyledLink>
            {/* <StyledLink
              active={PageName === 'escritorio-digital-para-advogados'}
              extendedMenu={extendedMenu}
              to="/escritorio-digital-para-advogados"
              onClick={() => {
                handleCloseMenuOnClickMobile();
              }}
            >
              <HiOfficeBuilding size={IconsSize} />
              <div>Escritório Digital</div>
            </StyledLink> */}

            <StyledLink
              active={PageName === 'assistente'}
              extendedMenu={extendedMenu}
              to="/assistente"
              onClick={handleCloseMenuOnClickMobile}
            >
              <SiGooglemessages size={IconsSize} />
              <div>Assistente Digital </div>
            </StyledLink>
            <StyledLink
              active={PageName === 'conciliacao'}
              extendedMenu={extendedMenu}
              to="/conciliacao"
              onClick={handleCloseMenuOnClickMobile}
            >
              <FaHandshake size={IconsSize} />
              <div>Conciliação</div>
            </StyledLink>
            <StyledLink
              active={PageName === 'materias'}
              extendedMenu={extendedMenu}
              to="/materias"
              onClick={() => {
                handleCloseMenuOnClickMobile();
              }}
            >
              <BsFilePost size={IconsSize} />
              <div>Matérias </div>
            </StyledLink>

            <StyledLink
              active={PageName === 'contato'}
              extendedMenu={extendedMenu}
              to="/contato"
              onClick={() => {
                handleCloseMenuOnClickMobile();
              }}
            >
              <BiSupport size={IconsSize} />
              <div>Contato</div>
            </StyledLink>
          </MenuItens>
          {/* {VisibleOnCollapse.map(
            ({ item, key, props }) =>
              item && (
                <animated.div
                  key={key}
                  style={{ ...props, zIndex: 9000 }}
                ></animated.div>
              )
          )} */}
          {!extendedMenu && (
            <>
              <StyledLink
                style={{ marginTop: 'auto' }}
                active={PageName === 'any'}
                extendedMenu={extendedMenu}
                to="/cadastro"
                onClick={() => {
                  handleCloseMenuOnClickMobile();
                }}
              >
                <GoSignIn size={IconsSize} />
                <div>Cadastre-se</div>
              </StyledLink>
              <StyledLink
                style={{ marginBottom: '2rem' }}
                active={PageName === 'any'}
                extendedMenu={extendedMenu}
                to="/login"
                onClick={() => {
                  handleCloseMenuOnClickMobile();
                }}
              >
                <FaUserCircle size={IconsSize} />
                <div>Entrar</div>
              </StyledLink>
            </>
          )}
          {extendedMenu && (
            <div
              className="footerMenu"
              style={{
                zIndex: 9000,
                marginTop: 'auto',
                marginBottom: ScreenMD ? '3rem' : 0
              }}
            >
              <ButtonSignIn
                extendedMenu={extendedMenu}
                to="/cadastro"
                onClick={handleCloseMenuOnClickMobile}
              >
                <button>Cadastre-se</button>
              </ButtonSignIn>
              <SignInAndUp
                active={false}
                extendedMenu={extendedMenu}
                to="/login"
                onClick={handleCloseMenuOnClickMobile}
              >
                <FaUserCircle size={IconsSize} />
                <span>Minha Conta</span>
              </SignInAndUp>
            </div>
          )}
        </Menu>
      </CollapsedSidebarMenu>
    </GeralMenu>
  );
};
export default CollapsedSidebar;
