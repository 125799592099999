import { AxiosResponse } from 'axios';
import api from '../../services/api';

interface IuguCreatePaymentMethodProps {
  user_iugu_account_reference_id: string;
  description: string;
  token: string;
  set_as_default: 'true' | 'false';
}

export interface PaymentMethodProps {
  id: string;

  description?: string;
  item_type: string;
  data: {
    holder_name: string;
    display_number: string;
    brand: string;
    month: number;
    year: number;
  };
}

export async function IuguCreatePaymentMethod({
  set_as_default,
  token,
  user_iugu_account_reference_id,
  description
}: IuguCreatePaymentMethodProps): Promise<AxiosResponse<PaymentMethodProps>> {
  const dataAPI: Omit<
    IuguCreatePaymentMethodProps,
    'user_iugu_account_reference_id'
  > = {
    description,
    token,
    set_as_default
  };
  return await api.post<PaymentMethodProps>(
    `/iugu/customers/${user_iugu_account_reference_id}/payment_methods`,
    dataAPI
  );
}

export interface IuguPaymentMethodsProps {
  id: string;
  description: string;
  item_type: string;
  data: Data;
}
interface Data {
  holder_name: string;
  display_number: string;
  brand: string;
  month: number;
  year: number;
}

export async function IuguGetPaymentMethods(
  costumer_id: string
): Promise<AxiosResponse<IuguPaymentMethodsProps[]>> {
  return await api.get<IuguPaymentMethodsProps[]>(
    `/iugu/customers/${costumer_id}/payment_methods`
  );
}
interface RemovePaymentMethodProps {
  costumer_id: string;
  payment_method_id: string;
}
export async function IuguRemovePaymentMethod({
  costumer_id,
  payment_method_id
}: RemovePaymentMethodProps): Promise<AxiosResponse<any>> {
  return await api.delete<any>(
    `/iugu/customers/${costumer_id}/payment_methods/${payment_method_id}`
  );
}

interface ChangePaymentMethodProps {
  costumer_id: string;
  payment_method_id: string;
}

export async function IuguChangeDefaultPaymentMethod({
  costumer_id,
  payment_method_id
}: ChangePaymentMethodProps): Promise<AxiosResponse<any>> {
  return await api.put<any>(`/iugu/customers/${costumer_id}`, {
    default_payment_method_id: payment_method_id
  });
}
