import React, { createContext, useContext, useEffect, useState } from 'react';
import appSocket from '../services/webSocket';
import { useMediaQuery } from 'react-responsive';

interface ResponsivenessContextProps {
  ScreenXS: boolean;
  ScreenSM: boolean;
  ScreenMD: boolean;
  ScreenLG: boolean;
  ScreenXL: boolean;
  ScreenXXL: boolean;
  isRetina: boolean;
  isPortrait: boolean;
  setShowMenuExpandedOnMobileState(state: boolean): void;
  ShowMenuExpandedOnMobileState: boolean;

  SetIsCollapsedMobileMenuVisible(state: React.SetStateAction<boolean>): void;
  IsCollapsedMobileMenuVisible: boolean;
}

const ResponsivenessContext = createContext<ResponsivenessContextProps>(
  {} as ResponsivenessContextProps
);

export const ResponsivenessProvider: React.FC = ({ children }) => {
  const ScreenXS = useMediaQuery({
    query: '(max-width: 480px)'
  });
  const ScreenSM = useMediaQuery({
    query: '(max-width: 576px)'
  });
  const ScreenMD = useMediaQuery({
    query: '(max-width: 768px)'
  });
  const ScreenLG = useMediaQuery({
    query: '(max-width: 992px)'
  });

  const ScreenXL = useMediaQuery({
    query: '(max-width: 1200px)'
  });
  const ScreenXXL = useMediaQuery({
    query: '(max-width: 1600px)'
  });

  const [
    IsCollapsedMobileMenuVisible,
    SetIsCollapsedMobileMenuVisible
  ] = useState(true);

  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const [
    ShowMenuExpandedOnMobileState,
    setShowMenuExpandedOnMobileState
  ] = useState(false);

  // {
  //   xs: '480px',
  //   sm: '576px',
  //   md: '768px',
  //   lg: '992px',
  //   xl: '1200px',
  //   xxl: '1600px',
  // }

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       Return                                         //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  return (
    <ResponsivenessContext.Provider
      value={{
        ScreenXS,
        ScreenSM,
        ScreenMD,
        ScreenLG,
        ScreenXL,
        ScreenXXL,
        isRetina,
        isPortrait,
        setShowMenuExpandedOnMobileState,
        ShowMenuExpandedOnMobileState,
        SetIsCollapsedMobileMenuVisible,
        IsCollapsedMobileMenuVisible
      }}
    >
      {children}
    </ResponsivenessContext.Provider>
  );
};

export function useResponsiveness(): ResponsivenessContextProps {
  const context = useContext(ResponsivenessContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
