import { AxiosResponse } from 'axios';
import { IUser } from '../../@types/IUser';
import { IUserInformation } from '../../@types/IUserInformation';
import api from '../../services/api';

export function GetUserData(userId: string): Promise<AxiosResponse<IUser>> {
  return api.get<IUser>(`/user/id/${userId}`);
}

export function GetUserInformation(userId: string) {
  return api.get<IUserInformation>(`/user_information/id/${userId}`);
}

export function EditUser(attributes: Partial<IUser>) {
  return api.put<IUser>(`/user`, attributes);
}

export function GetUserInformationFromEmail(
  email: string
): Promise<AxiosResponse<IUser>> {
  return api.get<IUser>(`/user/email/${email}`);
}

export interface ValideAccountProps {
  userId: string;
  key: string;
}

export function ValidateAccount({
  key,
  userId
}: ValideAccountProps): Promise<AxiosResponse<any>> {
  return api.patch(`/user/validate/${userId}/${key}`);
}
