import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons/lib';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import * as Styled from './styled.inputMask';

interface Props extends InputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  row?: boolean;
  label?: string;
  isTitle?: boolean;
  labelDefaultVisible?: boolean;
}

const TransparentInputMask: React.FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  icon: Icon,
  label,
  row,
  name,
  isTitle,
  labelDefaultVisible,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [isFocused, SetIsFocused] = useState(false);
  const [isFilled, SetIsFilled] = useState(false);

  const handleInputBlur = useCallback(() => {
    SetIsFocused(false);
    // transforma o fato de ter conteúdo ou não no value do input em boolean
    SetIsFilled(true);
  }, []);

  const handleInputFocus = useCallback(() => {
    SetIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      }
    });
  }, [fieldName, registerField]);

  return (
    <Styled.Container
      isTitle={isTitle || false}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      isColumned={!!row}
    >
      {label && (
        <Styled.Label
          isTitle={isTitle || false}
          isErrored={!!error}
          isFilled={isFilled}
          isFocused={isFocused}
          isColumned={!!row}
          labelDefaultVisible={labelDefaultVisible || false}
        >
          {label}
        </Styled.Label>
      )}
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        {...rest}
      />
      {error && (
        <Styled.Errors title={error}>
          <FiAlertTriangle color="red" size={20} />
        </Styled.Errors>
      )}
    </Styled.Container>
  );
};

export default TransparentInputMask;
