import styled from 'styled-components';
import defaultColorTheme from './defaultColorTheme';

const ConceptButton = styled.button`
  cursor: pointer;
  padding: 0.6rem 1.2rem 0.6rem 1.2rem;
  border: 0px solid ${defaultColorTheme.yellow};
  background: none;

  border-radius: 1rem;

  border-bottom: 2px solid ${defaultColorTheme.yellow};
  border-top: 2px solid ${defaultColorTheme.yellow};

  font-weight: 500;
  color: ${defaultColorTheme.text};

  transition: all 0.2s;
  &:hover {
    border: 2px solid ${defaultColorTheme.yellow};
  }
`;

export default ConceptButton;
