import { message as AntdMessage } from 'antd';
import { groupBy } from 'lodash';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Backend from '../config/backendAdress';
import { ChatUserProps } from '../pages/private/Shared/Chat/Chat';
import appSocket from '../services/webSocket';
import { useAuth } from './auth';
import { useNotifications } from './Notifications';
import { useToast } from './Toast';
export interface MessagesProps {
  id?: string;
  content: string;
  type:
    | 'message'
    | 'file'
    | 'record'
    | 'image'
    | 'event'
    | 'event_request'
    | 'videoConference'
    | 'agendaNotification'
    | 'newJuridicCorrespondenceNotification';
  send_by: string;
  chat_id: string;
  visualized: boolean;
  title?: string;

  request?: {
    requested: boolean;
    status?: 'accepted' | 'rejected' | 'verifying' | null;
    item_id: string;
    from: string;
  };

  created_at: string;
  updated_at: string;
}

interface MessagesContextProps {
  messages: MessagesProps[];
  setMessages(message: React.SetStateAction<MessagesProps[]>): void;
  ChatLoading: boolean;
  SetChatLoading(state: boolean): void;
  SetURL(URL: string): void;

  SetMessageUpdate(value: React.SetStateAction<boolean>): void;
  MessageUpdate: boolean;

  SetSoundNotificationsState(state: React.SetStateAction<boolean>): void;
  SoundNotificationsState: boolean;

  SetChatUserData(value: React.SetStateAction<ChatUserProps>): void;
  ChatUserData: ChatUserProps;
}

export interface NotificationProps {
  visualized: boolean;
  content: string;
  type: 'message' | 'advice' | 'newContact';
  icon: string;
}

const MessagesDataContext = createContext<MessagesContextProps>(
  {} as MessagesContextProps
);

export const MessagesDataProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const { addToast } = useToast();

  const { setNotifications } = useNotifications();

  const [messages, setMessages] = useState<MessagesProps[]>(
    [] as MessagesProps[]
  );
  const { url } = useRouteMatch();

  const [ChatUserData, SetChatUserData] = useState<ChatUserProps>(
    {} as ChatUserProps
  );

  const [ChatLoading, SetChatLoading] = useState(true);

  const [MessageUpdate, SetMessageUpdate] = useState(false);

  const [URL, SetURL] = useState('');

  const [SoundNotificationsState, SetSoundNotificationsState] = useState(true);

  useEffect(() => {
    const date = (item: MessagesProps) =>
      moment(item.created_at).format('DD/MM/YYYY');

    const OrderByDate = groupBy(messages, e => date(e));
  }, []);

  // function NewMessagesNotification() {
  //   if (
  //     url !== '/escritorio/chat' &&
  //     url !== '/painel/chat' &&
  //     url !== '/chat'
  //   ) {
  //     AntdMessage.info('Você tem novas mensagens');
  //   }

  // }
  function AudioNotify() {
    if (SoundNotificationsState) {
      new Audio(`${Backend.files}/${'system/received.mp3'}`).play();
    }
  }

  useEffect(() => {
    appSocket.on('receivedMessage', (message: MessagesProps) => {
      if (message.send_by !== user.id) {
        setMessages(n => [...n, message]);
        AudioNotify();

        SetMessageUpdate(n => !n);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SoundNotificationsState]);

  useEffect(() => {
    SetChatLoading(true);
    appSocket.on('previousMessages', (messages: MessagesProps[]) => {
      setMessages(messages);
      SetChatLoading(false);
    });
  }, []);

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       Return                                         //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  return (
    <MessagesDataContext.Provider
      value={{
        setMessages,
        messages,
        ChatLoading,
        SetChatLoading,
        SetURL,
        SetMessageUpdate,
        MessageUpdate,
        SetSoundNotificationsState,
        SoundNotificationsState,
        ChatUserData,
        SetChatUserData
      }}
    >
      {children}
    </MessagesDataContext.Provider>
  );
};

export function useMessages(): MessagesContextProps {
  const context = useContext(MessagesDataContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
