import React from 'react';
import { Container } from './Styled.tooltip';
interface TooltipProps {
  title: string;
  className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  className = '',
  children
}) => {
  return (
    <Container className={className}>
      {children}
      <span>
        <strong>{title}</strong>
      </span>
    </Container>
  );
};

export default Tooltip;
