// ---- Importing Section ----------------------------------------------------------------

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

// ---- Props interfaces -----------------------------------------------------------------
interface ExtendType {
  readonly extendedMenu?: boolean;
}

// ---- >styled components ---------------------------------------------------------------
export const GeralMenu = styled.aside<ExtendType>`
  z-index: 10000;
  position: fixed;
  /* @media only screen and (max-width: 1021px) {
    display: flex;
    position: relative;
  } */
`;
export const SidebarExtended = {
  extended: true
};
export const CollapsedSidebarMenu = styled.div<ExtendType>`
  background-color: ${props => props.theme.colors.menu};
  display: flex;
  float: left;
  flex-direction: column;

  /* O width irá variar para 15rem se o hook estiver ativo*/
  width: ${props => (props.extendedMenu ? '15rem' : '3.5rem')};

  transition: width 0.5s;
  height: 100vh;
`;

export const MenuItens = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1021px) {
  }
`;

interface MenuItemProps {
  readonly extendedMenu?: boolean;
  active: boolean;
}

export const StyledLink = styled(Link)<MenuItemProps>`
  position: relative;
  display: flex;
  /* align and justify must change to left when menu expands*/
  align-items: left;
  justify-content: left;
  /* padding: 0 0 0 1rem; must be applied when menu extended*/
  padding: 0 0 0rem 0.8rem;

  border-left: 3px solid transparent;
  ${props =>
    props.active &&
    css`
      border-left: 3px solid ${props => props.theme.colors.primary};
    `}

  text-decoration: none;
  color: ${props => props.theme.colors.white};
  transition: all 0.3s;
  svg {
    margin: ${props =>
      props.extendedMenu ? '0.7rem 0 0.75rem 0;' : '0.5rem 0 0.55rem 0;'};
  }
  img {
    width: 1.5rem;
    margin: ${props =>
      props.extendedMenu ? '0.6rem 0 0.65rem 0;' : '0.4rem 0 0.45rem 0;'};
  }
  div {
    ${props =>
      props.extendedMenu
        ? ``
        : css`
            position: fixed;
            visibility: hidden;
            background-color: ${props => props.theme.colors.primary};
            margin-left: 2.5rem;
            padding: 0.8rem 0.9rem 0.8rem 0.7rem;
            border-radius: 0 5px 5px 0;
            opacity: 0;
          `}
    display: flex;
    position: fixed;

    align-self: center;
    padding-left: 2.7rem;
    transition: opacity 0.5s;
    color: ${props => props.theme.colors.white};
    font-weight: 500;
  }
  svg {
    color: ${props => props.theme.colors.white};
  }
  button {
    display: flex;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.menu};
    /* the SVG must be filled with black*/
    svg {
      color: ${props => props.theme.colors.menu};
    }
    div {
      color: ${props => props.theme.colors.title};

      ${props =>
        props.extendedMenu
          ? ``
          : css`
              visibility: visible;

              background-color: ${props => props.theme.colors.primary};

              margin-left: 2.5rem;
              padding: 0.78rem 0.9rem 0.78rem 0.6rem;
              border-radius: 0 5px 5px 0;

              opacity: 1;
            `}
      transition: opacity 0.5s;
    }
  }
`;

export const LogoDP = styled.div<ExtendType>`
  ${props =>
    props.extendedMenu
      ? `
      display: none;
      position: fixed;
      visibility: hidden;
      opacity: 0;
      `
      : ''}
  transition: opacity 1s;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2rem 0 1.5rem 0;
  img {
    height: 3.5rem;
  }
`;
export const LogoDPExtended = styled.div<ExtendType>`
  ${props =>
    props.extendedMenu
      ? `

      `
      : `display: none;
      position: fixed;
      visibility: hidden;
      opacity: 0;`}
  transition: opacity 1s;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 3rem 0 2rem 0;
  img {
    height: 3.5rem;
  }
  /* @media only screen and (max-width: 1021px) {
    position: relative;
    opacity: 1;
    visibility: visible;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem 0;
    img {
      height: 2.5rem;
    }
  } ; */
`;

export const CollapseMenu = styled.div`
  display: fixed;

  position: absolute;
  top: 0rem;
  left: 13rem;
  cursor: pointer;

  border-radius: 0.4rem;
  transition: all 0.2s;

  .script {
    height: 100%;
    color: ${props => props.theme.colors.text};
    font-weight: 600;
    font-size: 0.85rem;
    margin-left: 0.1rem;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;

    transition: all 0.2s;

    width: 7.5rem;

    text-align: center;

    background: ${props => props.theme.colors.primary};
    opacity: 0;
  }

  button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;

    display: flex;
  }
  svg {
    margin: 0.5rem;
    color: ${props => props.theme.colors.white};
    transition: all 0.2s;
    height: 1.2rem;
  }

  &:hover {
    svg {
      color: ${props => props.theme.colors.dark};
    }
    .script {
      visibility: visible;
      opacity: 1;
    }
    background: ${props => props.theme.colors.primary};
  }
`;

export const SignInAndUp = styled(StyledLink)<ExtendType>`
  ${props =>
    props.extendedMenu
      ? css`
          span {
            transition: all 0.5s;
            visibility: visible;
          }
          svg {
            transition: all 0.5s;
            margin-right: 1rem;
          }
          &:hover {

            div {
              color: ${props => props.theme.colors.primary};
            }
            svg {
              padding-right: 1rem
              color: ${props => props.theme.colors.primary};
            }
          }
        `
      : css`
          span {
            visibility: hidden;
            display: none;
          }
          svg {
            padding-right: 0rem;
          }
        `}
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StyledLinkLast = styled(StyledLink)<ExtendType>``;

export const FinalMenu = styled.div``;

export const ButtonSignIn = styled(Link)<ExtendType>`
  text-decoration: none;
  /* margin: 0rem 0rem 1rem 2.5rem; */
  padding-bottom: 1.5rem;
  button {
    cursor: pointer;

    background-color: ${props => props.theme.colors.primary};
    border: 0.4rem solid ${props => props.theme.colors.primary};
    border-radius: 1rem;
    font-weight: 600;

    ${props =>
      props.extendedMenu
        ? css`
            visibility: visible;
            padding: 0.3rem 1rem 0.3rem 1rem;
            opacity: 1;
            transition: opacity 0.5s 0.5s;
          `
        : css`
            visibility: hidden;
            opacity: 0;
          `}

    color: ${props => props.theme.colors.text};
    transition: all 0.2s;
    &:hover {
      background-color: ${props => props.theme.colors.primaryDarker};
      border-color: ${props => props.theme.colors.primaryDarker};
    }
  }
`;
export const MenuMobile = styled.button<ExtendType>`
  display: none;
  /* @media only screen and (max-width: 1021px) {
    display: flex;
    width: 50px;
    padding: 0;
    background: none;
    border: none;
    align-items: center;
    justify-content: center;
    transition-delay: 1s;
    svg {
      color: white;
    }
    button {
      transition-delay: 1s;
    }
  } */
`;

export const HeaderMobile = styled.div<ExtendType>`
  /* @media only screen and (max-width: 1021px) {
    display: inline-grid;
    grid-template-columns: 20% 60% 20%;
    width: 100vw;
    border: none;
    background: none;
    align-items: center;
    justify-content: center;
    &:hover {
    }
  } ; */
`;

export const Menu = styled.div<ExtendType>`
  display: flex;
  flex-direction: column;
  height: 100%;

  .footerMenu {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding-bottom: 2rem;
  }
`;

export const BackgroundCell = styled.div<ExtendType>``;

interface absoluteProps {
  absolute?: boolean;
  readonly extendedMenu?: boolean;
}

export const CellphoneMenu = styled(animated.div)<absoluteProps>`
  height: 4rem;
  width: 100vw;
  z-index: 9000;

  ${props =>
    props.absolute
      ? css``
      : css`
          position: fixed;
        `}
  background: ${props => props.theme.colors.menu};
`;

export const BlackBackground = styled(animated.div)<absoluteProps>`
  background: black;
  z-index: 99999 !important;
  height: 100vh;
  width: 100vw;
  position: fixed;
`;

export const SpanComingSoon = styled.span<ExtendType>`
  ${props =>
    props.extendedMenu
      ? ''
      : css`
          display: none;
        `}
  position: absolute;
  bottom: -0.3rem;
  left: 3.3rem;
  z-index: 100001;
  color: ${props => props.theme.colors.dark};
  border-radius: 1.2rem;
  background: ${props => props.theme.colors.primary};
  padding: 0.1rem 0.3rem;
  font-size: 0.6rem;
  font-weight: 600;
`;
