import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { has } from 'lodash';
import { IAvatars } from '../@types/IAvatars';
import { IChats } from '../@types/Ichats';
import { ILawyerInformation } from '../@types/ILawyerInformation';
import { UserProps } from '../hooks/auth';
import api from '../services/api';
import { GetSimpleUrlByFileName } from './RoutesFunctions/functions.Files';
import { GetLawyerInformation } from './RoutesFunctions/functions.Lawyers';
import { GetUserData } from './RoutesFunctions/functions.Users';

interface LoadUsersAvatarProps {
  usersId: string[];
}
interface VariableStringFromLawyerPlansProps {
  starter: string | React.ReactNode;
  professional: string | React.ReactNode;
  membership: string | React.ReactNode;
  partneshipMember: string | React.ReactNode;
}

export function VariableElementFromLawyerPlans(
  lawyerPlan: ILawyerInformation['plan_type'],
  {
    membership,
    partneshipMember,
    professional,
    starter
  }: VariableStringFromLawyerPlansProps
) {
  if (lawyerPlan === 'starter') {
    return starter;
  } else if (lawyerPlan === 'professional') {
    return professional;
  } else if (lawyerPlan === 'membership') {
    return membership;
  } else if (lawyerPlan === 'partneshipMember') {
    return partneshipMember;
  }
  return starter;
}
interface VariableFromUserType {
  ToUser: any;
  ToLawyer: any;
}

export function VariableElementFromUserType(
  user: UserProps,
  { ToLawyer, ToUser }: VariableFromUserType
) {
  if (user.type === 'lawyer') {
    return ToLawyer;
  }
  if (user.type === 'user') {
    return ToUser;
  }
  return ToUser;
}

export async function VerifyAccountActive(user: UserProps, history: any) {
  if (has(user, 'id')) {
    if (user.active === false) {
      try {
        const userData = await GetUserData(user.id);
        if (userData.data.active === false) {
          message.error('Verifique a sua conta');
          return history.replace('/verify_email');
        }
        user.active = true;
        return localStorage.setItem(
          '@DireitoPratico:user',
          JSON.stringify(user)
        );
      } catch (error) {}
    }
  }
}
export async function LoadUsersAvatar({
  usersId
}: LoadUsersAvatarProps): Promise<AxiosResponse<IAvatars>[]> {
  const RequestsPromises = usersId.map(async userId => {
    return await api.get<IAvatars>(`/avatar/id/${userId}`);
  });
  return Promise.all(RequestsPromises);
}

interface ShortFileNameProps {
  name: string;
  maxCaractereLimit?: number;
}

export function HandleFileName({
  maxCaractereLimit,
  name
}: ShortFileNameProps): string {
  if (!maxCaractereLimit || maxCaractereLimit === -1) {
    return name.slice(33);
  }
  if (name.length >= maxCaractereLimit) {
    return `${name.slice(33).substr(0, maxCaractereLimit)}...`;
  } else {
    return name.slice(33);
  }
}

interface HandleLinksProps {
  FileName: string;
}

export async function HandleFileLink({
  FileName
}: HandleLinksProps): Promise<string> {
  const urlData = await GetSimpleUrlByFileName({
    FileName
  });
  return urlData.data.url;
}

export function defineIfUserIsAdminChat(user: UserProps, Chat: IChats) {
  if (!Chat.users) {
    return false;
  }
  const isUserAdmin = Chat.users.some(item => item === user.id);
  return isUserAdmin || false;
}

export function WriteCorrectPlanName(
  plan: ILawyerInformation['plan_type']
): string {
  if (plan === 'partneshipMember') {
    return `Partneship Member`;
  }
  if (plan === 'membership') {
    return `Membership`;
  }
  if (plan === 'professional') {
    return `Professional`;
  }
  if (plan === 'starter') {
    return 'Starter';
  }
  return ``;
}

interface GetIuguClientIdFromLawyerProps {
  userId: string;
  lawyerData: ILawyerInformation;
  updateLawyerData(): Promise<AxiosResponse<ILawyerInformation> | void>;
}

export async function GetIuguCostumerIdFromLocalLawyer({
  lawyerData,
  userId,
  updateLawyerData
}: GetIuguClientIdFromLawyerProps): Promise<string | null> {
  if (!lawyerData.user_payment_reference) {
    const lawyerInformation = await GetLawyerInformation(userId);
    await updateLawyerData();
    return lawyerInformation.data.user_payment_reference || null;
  }
  return lawyerData.user_payment_reference;
}
interface UrlProps {
  SetUrl(url: React.SetStateAction<string>): void;
}
export const HandleUrlValidation = (
  text: string,
  { SetUrl }: UrlProps
): string => {
  const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/-,:;';
  const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh______';
  const p = new RegExp(a.split('').join('|'), 'g');
  const newText = text
    .toString()
    .toLowerCase()
    .trim()
    .replace(p, (c: any) => b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/&/g, '_and_') // Replace & with 'and'
    .replace(/[\s\W-]+/g, '_') // Replace spaces, non-word characters and dashes with a single dash (-)
    .replace(/[\s\W__]+/g, '_');

  SetUrl(newText);
  return newText;
};
