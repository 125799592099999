import styled, { css } from 'styled-components';
import { DefaultStyledArea } from '../../../../styles/DefaultStyledArea';

interface ContainerProps {
  mobile: boolean;
}

export const Container = styled.main<ContainerProps>`
  ${DefaultStyledArea.defaultPadding}

  /* overflow-y: scroll;
  overflow-x: hidden; */
  ${props =>
    props.mobile
      ? css`
          padding: 0 2rem;
        `
      : css`
          padding: 0 1rem;
        `}

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .center-full {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .vertical {
    flex-direction: column;
  }
`;

export const View = styled.div`
  ${DefaultStyledArea.defaultDashboard}
  width: 100%;
  max-width: 50rem;
`;

export const Rectangle = styled.div`
  width: 4rem;
  height: 0.2rem;
  background: ${props => props.theme.colors.primary};
`;

export const CreditCardFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

interface PaymentDescriptionProps {
  mobile: boolean;
}
export const PaymentDescription = styled.div<PaymentDescriptionProps>`
  display: flex;
  flex-direction: column;

  align-items: center;
  height: 100%;
  width: 100%;
`;
