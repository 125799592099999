import { AxiosResponse } from 'axios';
import { ISubscriptionsCurrent } from '../../@types/ISubscriptions';
import api from '../api';

export class SubscriptionService {
  api = api;

  GetUserSubscription(
    userId: string
  ): Promise<AxiosResponse<ISubscriptionsCurrent>> {
    return api.get<ISubscriptionsCurrent>(`/subscriptions/user/${userId}`);
  }
}
