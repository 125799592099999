import React, {
  createContext,
  useCallback,
  useContext,
  useEffect
} from 'react';
import usePersistedState from '../utils/usePersistedState';
import { useResponsiveness } from './Responsiveness';

interface SideMenuState {
  isExtended: boolean;
  setMenuExtended(): void;
  setMenuExtendedDeclarative(obj: Func): void;
}
interface Func {
  state: boolean;
}

const SideMenuExpandedContext = createContext<SideMenuState>(
  {} as SideMenuState
);

// eslint-disable-next-line react/prop-types
export const SideMenuExpandedContextProvider: React.FC = ({ children }) => {
  const [isExtended, setIsExtended] = usePersistedState('menuExpanded', false);
  const { ScreenMD } = useResponsiveness();

  const setMenuExtended = useCallback(() => {
    setIsExtended(!isExtended);
  }, [isExtended]);

  const setMenuExtendedDeclarative = ({ state }: Func) => {
    state && setIsExtended(state);
  };

  // useEffect(() => {
  //   !ScreenMD && setMenuExtendedDeclarative({ state: false });
  // }, [ScreenMD]);

  return (
    <SideMenuExpandedContext.Provider
      value={{ isExtended, setMenuExtended, setMenuExtendedDeclarative }}
    >
      {children}
    </SideMenuExpandedContext.Provider>
  );
};

export function UseSideMenuStatus(): SideMenuState {
  const context = useContext(SideMenuExpandedContext);

  if (!context) {
    throw new Error('The UseSideMenuStatus must be children of a provider,');
  }
  return context;
}
