import styled, { css } from 'styled-components';
import { DefaultStyledArea } from '../../styles/DefaultStyledArea';

export const Container = styled.main`
  padding: 0 2rem;
  .all-area-center {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
  }

  .button-add-new-record {
    border: none;
    background: none;
    height: 100%;
    cursor: pointer;
    padding: 0.8rem 1.5rem;
    border-bottom: 2px solid ${props => props.theme.colors.primary};

    transition: all 0.2s ease-in-out;

    font-size: 0.9rem;

    &:hover {
      background: rgba(240, 240, 240, 0.9);
    }
  }

  .record-reader {
    display: flex;
    align-items: center;
    svg {
      margin-right: 1rem;
    }
  }

  .settingsRecord {
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${props => props.theme.colors.primaryDarker};
    }
    svg:hover {
      color: ${props => props.theme.colors.primaryDarker};
    }
    .button-new-folder {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .header-view-record {
    display: flex;
  }

  .icon-hover-circle {
    svg {
      width: 100%;
      height: 100%;
      cursor: pointer;

      transition: all 0.2s ease-in-out;

      border: 3px solid rgba(255, 255, 255, 0);
      border-radius: 50%;
      color: ${props => props.theme.colors.lightGreyIcon};
      &:hover {
        background: rgba(240, 240, 240, 0.9);
      }
    }
  }

  .button-icon-hover-circle {
    background: none;
    border: none;

    svg {
      width: 100%;
      height: 100%;
      cursor: pointer;

      transition: all 0.2s ease-in-out;

      border: 3px solid rgba(255, 255, 255, 0);
      border-radius: 50%;
      color: ${props => props.theme.colors.lightGreyIcon};
      &:hover {
        background: rgba(240, 240, 240, 0.9);
      }
    }
  }
`;

export const View = styled.div`
  ${DefaultStyledArea.defaultDashboard}
`;

export const Date = styled.div`
  margin-left: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;

  color: ${props => props.theme.colors.lightText};
`;

export const RecordContent = styled.div`
  margin: 2rem 0;
  padding: 0 1rem;
`;

export const FileArea = styled.div`
  margin: 0 0 0rem 0rem;
`;

export const File = styled.div`
  position: relative;

  width: 10rem;
  height: 9rem;
  background: rgba(240, 240, 240, 0.5);
  word-break: normal;
  text-align: center;
  border-radius: 1rem;
  border: 1rem solid rgba(240, 240, 240, 0.5);

  transition: all 0.2s ease-in-out;

  cursor: pointer;
  .iconFile {
    display: flex;
    width: 100%;
    height: 50%;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    transform: scale(1.05);
    background: rgba(240, 240, 240, 0.7);
    border: 1rem solid rgba(240, 240, 240, 0.7);
  }
`;

export const ResponseArea = styled.div`
  margin: 0 1.5rem;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 1.2rem;
  padding: 1.5rem;

  .addFileIcon {
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;
interface featureProps {
  isActive: boolean;
}
interface TransparentButtonProps {
  darkBackground?: boolean;
}

export const TransparentButton = styled.button<TransparentButtonProps>`
  border: none;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.8rem 1.5rem;
  border-bottom: 2px solid ${props => props.theme.colors.primary};

  transition: all 0.2s ease-in-out;
  svg {
    margin-right: 0.3rem;
  }
  font-size: 0.9rem;

  &:hover {
    ${props =>
      props.darkBackground
        ? css`
            background: rgba(240, 240, 240, 0.1);
          `
        : css`
            background: rgba(240, 240, 240, 0.9);
          `}
  }
`;

export const FullTransparentButton = styled.button<TransparentButtonProps>`
  border: none;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.8rem 1.5rem;

  transition: all 0.2s ease-in-out;
  svg {
    margin-right: 0.3rem;
  }
  font-size: 0.9rem;

  &:hover {
    ${props =>
      props.darkBackground
        ? css`
            background: rgba(240, 240, 240, 0.1);
          `
        : css`
            background: rgba(240, 240, 240, 0.9);
          `}
  }
`;

export const ContactView = styled.div`
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border-radius: 1.5rem;
`;
export const Contact = styled.div<featureProps>`
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: all 0.2s ease-in-out;

  ${props =>
    props.isActive &&
    css`
      border-left: 2px solid ${props => props.theme.colors.primary};
      background: #f5f5f5;
    `}

  &:hover {
    background: #f5f5f5;
  }
`;
