import { shade } from 'polished';

export default {
  theme: 'dark',

  colors: {
    primary: '#F0C419',
    primaryDarker: shade(0.2, '#F0C419'),
    background: '#333',
    backgroundView: '#ffffff',
    title: '#f4ede8',
    text: '#f4ede8',
    lightText: 'grey',
    subTitle: 'grey',

    whiteOrDark: '#555555',
    darkOrWhite: '#2B2B2B',
    white: '#333333',
    lightGrey: 'grey',

    ChatHeader: '#D3D3D3',
    ChatMessages: '#D3D3D3',
    ChatSendArea: '#D3D3D3',

    menu: '#1F1F1F',
    headerDashboard: '#f2f7f7',
    footerDashboard: '#f2f2f2',
    menuDropdown: '#1A1A1A',

    DarkOrWhiteIcon: '#000',
    WhiteIcon: '#000',
    lightGreyIcon: 'grey',

    online: '#219653',
    offline: 'grey',
    membershipOrange: '#F29C1F',
    professionalGrey: 'rgba(106, 106, 106, 0.27)',
    starterGrey: 'rgba(185, 185, 185, 0.27)'
  }
};
