// import { io } from 'socket.io-client';

// const appSocket = io('http://127.0.0.1:3333');

// export default appSocket;

import { io } from 'socket.io-client';
import Backend from '../config/backendAdress';

interface ConnectSocketProps {
  roomId: string;
}
// export const ConnectSocketRoom = (roomId: string) => {
//   return io('Backend.baseURL', { query: { roomId } });
// };

const appSocket = io(Backend.baseURL, {transports: ['websocket']});

export default appSocket;
