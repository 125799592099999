import { Avatar, Modal, Row, Space, Tooltip, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { MdPhonelink, MdPhonelinkOff } from 'react-icons/md';
import { animated, useTransition } from 'react-spring';
import Backend from '../../config/backendAdress';
import { useResponsiveness } from '../../hooks/Responsiveness';
import { CenterFull } from '../../pages/private/Lawyers/LawyerPlans/Components/subcomponents/SelectCreditCardPaymentMethodsModal';
import { ChatUserProps } from '../../pages/private/Shared/Chat/Chat';
import { LinkArea } from '../../pages/private/Shared/VideoMettings/subcomponents/SenderNewVideoMeetingModal';
import { LoadUsersAvatar } from '../../utils/functions';
import Loading from '../Loading/Loading';
import * as S from './styled.ConferenceCallingScreen';
const { Title, Paragraph } = Typography;
interface ConferenceCallingScreenProps {
  caller: ChatUserProps;
  link: string;
  SetShowCallingState(state: React.SetStateAction<boolean>): void;
  ShowCallingState: boolean;
}

const ConferenceCallingScreen: React.FC<ConferenceCallingScreenProps> = ({
  caller,
  SetShowCallingState,
  ShowCallingState,
  link
}) => {
  const [LoadingState, SetLoadingState] = useState(false);

  const [AvatarLink, SetAvatar] = useState('');

  const { ScreenMD } = useResponsiveness();

  async function LoadAvatar() {
    SetLoadingState(true);
    const avatarPromise = await LoadUsersAvatar({ usersId: [caller.id] });
    Promise.resolve(avatarPromise).then(res => {
      SetAvatar(`${Backend.files}/${res[0].data.avatar}`);
      SetLoadingState(false);
    });
  }

  const audio = useMemo(
    () => new Audio(`${Backend.files}/${'system/incoming-conference.mp3'}`),
    []
  );

  useEffect(() => {
    audio.loop = true;
    audio.play();
  }, [audio]);

  function StopMusic() {
    audio.pause();
  }

  function handleUpCall() {
    StopMusic();
  }

  useEffect(() => {
    if (!caller.image) {
      LoadAvatar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caller]);

  const Main: React.FC = () => {
    return (
      <>
        <Modal
          title={<>Nova Chamada</>}
          visible={ShowCallingState}
          onCancel={() => {
            SetShowCallingState(false);
          }}
          zIndex={1000000001}
          width={ScreenMD ? '100vw' : '60vw'}
          centered
          destroyOnClose
          footer={<></>}
        >
          <div>
            <S.Container>
              <Row gutter={[32, 32]}>
                <div className="center-full">
                  <Space direction="vertical" size="large" align="center">
                    <Space direction="vertical" size="large" align="center">
                      <Title
                        level={3}
                        style={{ textAlign: 'center', marginTop: '1rem' }}
                      >
                        Solicitação de Reunião Online
                      </Title>
                      <Loading loading={LoadingState}>
                        <Avatar size={130} src={AvatarLink || caller.image} />
                      </Loading>
                      <Title
                        level={4}
                        style={{
                          textAlign: 'center'
                        }}
                      >{`${caller.first_name} ${caller.last_name}`}</Title>
                    </Space>
                    <Space direction="vertical" size="large">
                      <Space direction="vertical" style={{ maxWidth: '30rem' }}>
                        <LinkArea>
                          <Title level={5} className="title">
                            Copiar Link da Reunião:
                          </Title>
                          <Paragraph
                            className="link"
                            ellipsis={{
                              rows: 1
                            }}
                            copyable={{
                              tooltips: ['Copiar', 'Copiado!']
                            }}
                          >
                            {link}
                          </Paragraph>
                        </LinkArea>
                      </Space>
                    </Space>
                    <Space
                      direction="vertical"
                      size="large"
                      className="center-full"
                    >
                      <div
                        style={{
                          display: 'flex'
                        }}
                      >
                        <Tooltip
                          placement="top"
                          title={'Ir para chamada de vídeo'}
                        >
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginRight: '5rem' }}
                            onClick={() => handleUpCall()}
                          >
                            <S.AttendUp>
                              <MdPhonelink size={40} />
                            </S.AttendUp>
                          </a>
                        </Tooltip>
                        <Tooltip placement="top" title={'Recusar chamada'}>
                          <S.AttendOff
                            onClick={() => {
                              SetShowCallingState(false);
                              StopMusic();
                            }}
                          >
                            <MdPhonelinkOff size={40} />
                          </S.AttendOff>
                        </Tooltip>
                      </div>
                    </Space>
                  </Space>
                </div>
              </Row>
            </S.Container>
          </div>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Main />
    </>
  );
};

export default ConferenceCallingScreen;
