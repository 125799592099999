import { AxiosResponse } from 'axios';
import { INotifications } from '../../@types/INotifications';
import api from '../../services/api';
import appSocket from '../../services/webSocket';

interface SendNotificationProps {
  notification: Omit<INotifications, 'created_at' | 'updated_at' | 'id'>;
}
export function SendNotification({
  notification
}: SendNotificationProps): void {
  appSocket.emit('createNotification', notification);
}

export async function GetNotifications(
  userId: string
): Promise<AxiosResponse<INotifications[]>> {
  return await api.get<INotifications[]>(`/notification/user/${userId}`);
}
