import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  ILawyerInformation,
  ILawyerInformationSearched
} from '../@types/ILawyerInformation';

interface LawyerSearchProps {
  occupationOrSpecialization: string;
  searchLocation: string;
}

interface LawyerSearchContextProps {
  searchData: LawyerSearchProps;
  SearchLawyer(
    occupationOrSpecialization: string,
    searchLocation: string
  ): void;
  SetLawyerSearchResults(
    lawyers: React.SetStateAction<ILawyerInformationSearched[]>
  ): void;
  LawyerSearchResults: ILawyerInformationSearched[];
}

const LawyerSearchContext = createContext<LawyerSearchContextProps>(
  {} as LawyerSearchContextProps
);

export const SearchLawyerProvider: React.FC = ({ children }) => {
  const [searchData, setSearchData] = useState<LawyerSearchProps>(
    {} as LawyerSearchProps
  );

  const SearchLawyer = useCallback(
    (occupationOrSpecialization: string, searchLocation: string) => {
      const data = {
        occupationOrSpecialization,
        searchLocation
      };
      setSearchData(data);
    },
    []
  );

  // ---- SearchResults --------------------------------------------------------------------

  const [LawyerSearchResults, SetLawyerSearchResults] = useState<
    ILawyerInformation[]
  >([] as ILawyerInformation[]);

  return (
    <>
      <LawyerSearchContext.Provider
        value={{
          SearchLawyer,
          searchData,
          SetLawyerSearchResults,
          LawyerSearchResults
        }}
      >
        {children}
      </LawyerSearchContext.Provider>
    </>
  );
};

export function useLawyerSearch(): LawyerSearchContextProps {
  const context = useContext(LawyerSearchContext);

  if (!context) {
    throw new Error(
      'useLawyerSearch must be used in a children component of a Provider.'
    );
  }

  return context;
}
