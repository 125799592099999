import { shade } from 'polished';
export default {
  background: '#f9f9f9',
  yellow: '#F0C419',
  title: '#2E2E2E',
  text: '#2B2B2B',
  titleGray: '#282828',
  textGray: '#444444',
  textExtraGray: 'grey',
  menu: '#1F1F1F',
  menuDropdown: '#1A1A1A',
  white: '#fff',
  yellowDarker: shade(0.05, '#F0C419'),
  online: '#219653',
  darkIcon: '#000',
  membershipOrange: '#F29C1F',
  professionalGrey: 'rgba(106, 106, 106, 0.27)',
  starterGrey: 'rgba(185, 185, 185, 0.27)',
  grey: 'grey',
  offline: 'grey;'
};
