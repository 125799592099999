import axios from 'axios';
import Backend from '../config/backendAdress';

const api = axios.create({
  baseURL: Backend.baseURL
});

export const ServerEscavadorAPI = axios.create({
  baseURL: `https://api.escavador.com/api/v1`,

  headers: {
    'Content-Type': 'application/json',
    authorization: 'Bearer ' + process.env.ESCAVADOR_API_TOKEN || ''
  }
});

// ---- Ensure Valid Token ---------------------------------------------------------------

export default api;
