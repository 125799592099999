import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Modal, Button, Result, List, Typography } from 'antd';

import { IuguPaymentMethodsProps } from '../../../../../../utils/IuguFunctions/PaymentMethods';
import { StyledButton } from '../../../../../../components/SuperButton/styles';
import ConceptButton from '../../../../../../styles/ConceptButton';
import { TransparentButton } from '../../../../../../components/CreateRecord/styles.CreateRecordDrawer';
import styled, { css } from 'styled-components';
import Cards from 'react-credit-cards';
import AddNewCreditCardDrawer from '../../../SubscriptionSettings/Components/addNewCreditCardDrawer';
import { useResponsiveness } from '../../../../../../hooks/Responsiveness';
const { Text } = Typography;
interface CreditCardPaymentMethodModalProps {
  IsSelectCreditCardModalVisible: boolean;
  SetIsSelectCreditCardModalVisible(state: React.SetStateAction<boolean>): void;

  SelectedCard: IuguPaymentMethodsProps;
  SetSelectedCard(card: React.SetStateAction<IuguPaymentMethodsProps>): void;

  AddNewCardState: boolean;
  SetAddNewCardState(state: React.SetStateAction<boolean>): void;
  onNewCardAdded?(): void;
  onCancel?(): void;

  paymentMethods: IuguPaymentMethodsProps[];

  DefaultPaymentMethod: IuguPaymentMethodsProps;
  onCardSelected(card: IuguPaymentMethodsProps): void;
}

export const CenterFull = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

interface SelectedDivProps {
  selected: boolean;
}

const SelectDiv = styled.div<SelectedDivProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  border-radius: 1.2rem;

  transition: all 0.2s ease-in-out;

  ${props =>
    props.selected
      ? css`
          background: rgba(217, 217, 217, 0.4);
        `
      : css`
          background: transparent;
        `}

  span {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    font-weight: 400;
    padding: 0.3rem 0.3rem;

    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .select {
    visibility: hidden;
  }

  .selected {
    visibility: hidden;

    ${props =>
      props.selected &&
      css`
        visibility: visible;
      `}
  }

  &:hover {
    background: rgba(217, 217, 217, 0.2);
    .select {
      visibility: visible;
      ${props =>
        props.selected &&
        css`
          visibility: hidden;
        `}
    }
  }
`;

const SelectCreditCardPaymentMethodModal: React.FC<CreditCardPaymentMethodModalProps> = ({
  IsSelectCreditCardModalVisible,
  SetIsSelectCreditCardModalVisible,
  paymentMethods,
  SelectedCard,
  SetSelectedCard,
  SetAddNewCardState,
  onCancel,
  onNewCardAdded: onNewCardAddedNew,
  DefaultPaymentMethod,
  onCardSelected
}) => {
  const [CreditCards, SetCreditCards] = useState<IuguPaymentMethodsProps[]>(
    [] as IuguPaymentMethodsProps[]
  );
  const { ScreenMD } = useResponsiveness();
  const [
    AddNewCreditCardDrawerVisible,
    SetAddNewCreditCardDrawerVisible
  ] = useState(false);

  useEffect(() => {
    const creditCardsData = paymentMethods.filter(
      item => item.item_type === 'credit_card'
    );
    if (creditCardsData.length > 0) {
      SetCreditCards(creditCardsData);
    }
  }, [paymentMethods]);

  function addNewCard() {
    SetAddNewCreditCardDrawerVisible(true);
    SetAddNewCardState(true);
    SetIsSelectCreditCardModalVisible(true);
  }

  function onNewCardAdded() {
    SetAddNewCardState(false);
    SetAddNewCreditCardDrawerVisible(false);
    onNewCardAddedNew && onNewCardAddedNew();
  }

  function onAddNewCardProcessCanceled() {
    SetAddNewCardState(false);
    SetIsSelectCreditCardModalVisible(true);
    SetAddNewCreditCardDrawerVisible(false);
  }

  function HandleSelectedCard(card: IuguPaymentMethodsProps) {
    SetSelectedCard(card);
    onCardSelected(card);
  }

  return (
    <>
      <AddNewCreditCardDrawer
        SetAddNewCreditCardDrawerVisible={SetAddNewCreditCardDrawerVisible}
        IsAddNewCreditCardDrawerVisible={AddNewCreditCardDrawerVisible}
        onSubmit={onNewCardAdded}
        onCancel={onAddNewCardProcessCanceled}
      />
      <Modal
        title="Clique em um cartão para selecioná-lo"
        visible={IsSelectCreditCardModalVisible}
        onCancel={() => {
          SetIsSelectCreditCardModalVisible(false);
          onCancel && onCancel();
          SetSelectedCard({} as IuguPaymentMethodsProps);
        }}
        zIndex={1000001}
        closable
        centered
        footer={
          <>
            <Space align="center">
              <ConceptButton
                onClick={() => {
                  SetIsSelectCreditCardModalVisible(false);
                  onCancel && onCancel();
                  SetSelectedCard({} as IuguPaymentMethodsProps);
                }}
              >
                Cancelar
              </ConceptButton>
              <StyledButton
                onClick={() => {
                  SetIsSelectCreditCardModalVisible(false);
                }}
              >
                Confirmar
              </StyledButton>
            </Space>
          </>
        }
      >
        <Row gutter={[32, 32]} justify="center">
          {CreditCards.length === 0 && (
            <Col span={24} style={{ width: '100%', height: '100%' }}>
              <CenterFull>
                <Result
                  title="Você não tem nenhum cartão cadastrado"
                  extra={
                    <TransparentButton onClick={addNewCard}>
                      Adicionar Novo Cartão
                    </TransparentButton>
                  }
                />
              </CenterFull>
            </Col>
          )}
          <Col span={22} style={{ width: '100%', height: '100%' }}>
            <List
              itemLayout="horizontal"
              dataSource={CreditCards}
              renderItem={item => (
                <List.Item
                  actions={[
                    <>
                      {item.id === SelectedCard.id ? (
                        <>
                          <Text strong>Selecionado</Text>
                        </>
                      ) : (
                        <>
                          <TransparentButton
                            onClick={() => HandleSelectedCard(item)}
                          >
                            Selecionar
                          </TransparentButton>
                        </>
                      )}
                    </>
                  ]}
                >
                  <List.Item.Meta
                    title={`Cartão ${item.data.brand}`}
                    description={
                      <>
                        <Space direction="vertical">
                          <Space>
                            <strong>Em nome de:</strong>{' '}
                            <Text>{item.data.holder_name}</Text>
                          </Space>
                          <Space>
                            <strong>Terminado em:</strong>{' '}
                            <Text>{item.data.display_number}</Text>
                          </Space>
                          <Space>
                            <strong>Vencimento:</strong>{' '}
                            <Text>{`${item.data.month}/${item.data.year}`}</Text>
                          </Space>
                        </Space>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[32, 32]}>
          <Col span={24} style={{ width: '100%', height: '100%' }}>
            <TransparentButton onClick={addNewCard}>
              Adicionar Novo Cartão
            </TransparentButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SelectCreditCardPaymentMethodModal;
