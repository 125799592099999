import React, { useState } from 'react';
import { Row, Col, Space, Drawer, Switch, Typography } from 'antd';
import Loading from '../../../../../components/Loading/Loading';
import { useResponsiveness } from '../../../../../hooks/Responsiveness';
import AddNewCreditCardPaymentMethodForm from '../../../Shared/payments/Components/CreditCardForm';
import { PaymentMethodProps } from '../../../../../utils/IuguFunctions/PaymentMethods';
import ConceptButton from '../../../../../styles/ConceptButton';
interface AddNewCreditCardDrawerProps {
  onSubmit(data: PaymentMethodProps): void;
  SetAddNewCreditCardDrawerVisible(state: React.SetStateAction<boolean>): void;
  IsAddNewCreditCardDrawerVisible: boolean;
  onCancel?(): void;
}
const { Text } = Typography;
const AddNewCreditCardDrawer: React.FC<AddNewCreditCardDrawerProps> = ({
  IsAddNewCreditCardDrawerVisible,
  SetAddNewCreditCardDrawerVisible,
  onSubmit,
  onCancel
}) => {
  const [LoadingState, SetLoadingState] = useState(false);
  const { ScreenMD, ScreenSM } = useResponsiveness();
  const [DefaultPaymentMethodState, SetDefaultPaymentMethodState] = useState(
    true
  );

  function UpdatePaymentMethods(data: PaymentMethodProps) {
    onSubmit(data);
  }

  return (
    <>
      <Drawer
        title="Adicionar Novo Meio de Pagamento"
        placement="right"
        closable={true}
        footer={
          <>
            <ConceptButton
              onClick={() => SetAddNewCreditCardDrawerVisible(false)}
            >
              Cancelar
            </ConceptButton>
          </>
        }
        onClose={() => {
          SetAddNewCreditCardDrawerVisible(false);
          onCancel && onCancel();
        }}
        visible={IsAddNewCreditCardDrawerVisible}
        zIndex={10000001}
        width={ScreenSM ? '100vw' : '50%'}
        destroyOnClose
      >
        <Loading loading={LoadingState}>
          <Space direction="vertical" size="large">
            <Space align="center">
              <Switch
                defaultChecked
                onChange={() => SetDefaultPaymentMethodState(n => !n)}
              />
              <Text type="secondary">
                Definir como meio de pagamento padrão.
              </Text>
            </Space>

            <AddNewCreditCardPaymentMethodForm
              SubmitButtonText={'Adicionar Novo Cartão'}
              SubmitCallback={data => UpdatePaymentMethods(data)}
              set_as_default={DefaultPaymentMethodState ? 'true' : 'false'}
            />
          </Space>
        </Loading>
      </Drawer>
    </>
  );
};

export default AddNewCreditCardDrawer;
