import React, { createContext, useContext, useState } from 'react';

import { IRecords } from '../@types/IRecords';

interface RecordDataProps {
  setRecordData(record: IRecords): void;
  recordData: IRecords;
}

// Todas as Funções ligadas ao authContext é bacana que fiquem centralizadas no arquivo

// Precisamos deixar o objeto vazio para que o auth apenas seja preenchido após login
// este é um pequeno hack para atingir este objetivo
const RecordDataContext = createContext<RecordDataProps>({} as RecordDataProps);

// função de preenchimento de valor inicial do storage data para
// pegar o localstorage se estiver armazenado ou inciar vazio caso contrário
export const RecordDataProvider: React.FC = ({ children }) => {
  const [recordData, setRecordData] = useState<IRecords>({} as IRecords);

  return (
    //  {/*  O AuthContext.Provider dá a todos os componentes children acesso a informação
    // que ele contem, para inicializa-lo precisamos definir o 'value' */}
    <RecordDataContext.Provider
      value={{
        setRecordData,
        recordData
      }}
    >
      {/* Tudo que o AuthProvider receber como filho (componente dentro do
        componente), será repassado para eles. */}
      {children}
    </RecordDataContext.Provider>
  );
};

// useAuth() criado componentização no arquivo do contexto para evitar o useContext(AuthContext); toda vez

export function useRecordData(): RecordDataProps {
  const context = useContext(RecordDataContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
