import { IAvatars } from '../@types/IAvatars';
import { IChats } from '../@types/Ichats';
import { IUser } from '../@types/IUser';
import Backend from '../config/backendAdress';
import { UserProps } from '../hooks/auth';
import { ChatUserProps } from '../pages/private/Shared/Chat/Chat';
import api from '../services/api';
import { defineIfUserIsAdminChat, HandleFileLink } from './functions';
import { GetUserAvatar } from './RoutesFunctions/functions.avatar';
import { GetUserData } from './RoutesFunctions/functions.Users';
import { has } from 'lodash';
import { message } from 'antd';
interface loadUserInformationProps {
  callback?(data: ChatUserProps[]): void;
  Chats: IChats[];
  user: UserProps;
}

export const GetUsersFromChats = ({
  Chats,
  callback,
  user
}: loadUserInformationProps): void => {
  //  Handle Get data of each User in the chat
  if (Chats && user !== undefined) {
    const allIds = Chats
      ? Chats.map(item => {
          return item.users ? item.users : '';
        })
      : [];

    const allIdsFlat = allIds.flat();

    const ApiContactList = allIdsFlat
      ? allIdsFlat.map(async UserApiId => {
          if (UserApiId && UserApiId !== user.id) {
            const responseAPI = await api.get<IUser>(`/user/id/${UserApiId}`);

            const responseAPIAvatar = await api.get<IAvatars>(
              `/avatar/id/${UserApiId}`
            );

            // dealing with user data

            if (!has(responseAPI, 'data')) {
              return {} as ChatUserProps;
            }

            const userAPI = responseAPI.data;

            // just first word of firstname

            const UserFirstNameStrings = userAPI.first_name
              ? userAPI.first_name.split(' ')
              : '';
            const JustFirstName = UserFirstNameStrings[0];

            //  Just the last word of the lastName

            const UserLastNameStrings = userAPI.last_name
              ? userAPI.last_name.split(' ')
              : '';
            const JustLastName =
              UserLastNameStrings[UserLastNameStrings.length - 1];

            // join the data to the contact list

            const ChatFrom = Chats.filter(chat => {
              return chat.users ? chat.users.indexOf(UserApiId) >= 0 : '';
            });

            const chatIdFiltered: any = ChatFrom[0].id;

            const join: ChatUserProps = {
              image: `${Backend.files}/${responseAPIAvatar.data.avatar}`,
              id: `${userAPI.id}`,
              first_name: `${JustFirstName}`,
              last_name: `${JustLastName}`,
              chat_id: `${chatIdFiltered}`,
              tag: ChatFrom[0].tag || 'InitialContact',
              type: userAPI.type
            };

            return join;
          }
          return {} as ChatUserProps;
        })
      : [];

    // solving all promises

    Promise.all(ApiContactList).then(data => {
      const joined = data.filter(value => Object.keys(value).length !== 0);
      callback && callback(joined);
    });
  }
};

interface updateProps {
  UsersCallback?(data: ChatUserProps[]): void;
  ChatsCallback?(data: IChats[]): void;
  user: UserProps;
}

export const LoadContactInformation = async ({
  ChatsCallback,
  UsersCallback,
  user
}: updateProps): Promise<void> => {
  try {
    const response = await api.get<IChats[]>(`/chat/user/${user.id}`);
    GetUsersFromChats({
      Chats: response.data,
      user,
      callback: UsersCallback
    });
    ChatsCallback && ChatsCallback(response.data);
  } catch (error) {}
};

interface AddChatToContactsProps {
  chat: IChats;
  SetContacts(contacts: React.SetStateAction<ChatUserProps[]>): void;
  user: UserProps;
}

export async function AddChatToContacts({
  SetContacts,
  chat,
  user
}: AddChatToContactsProps): Promise<void> {
  try {
    const userId = chat.users && chat.users?.filter(item => item !== user.id);

    if (userId === undefined) {
      return;
    }

    const userToAdd = await GetUserData(userId[0]);

    const userAvatar = await GetUserAvatar(userToAdd.data.id);
    let url: string;
    if (userAvatar.data.avatar === 'default-avatar.png') {
      url = `https://ui-avatars.com/api/?name=${userToAdd.data.first_name}+${userToAdd.data.last_name}`;
    } else {
      url = await HandleFileLink({
        FileName: userAvatar.data.avatar || ''
      });
    }

    let userTag:
      | 'InitialContact'
      | 'Negotiation'
      | 'Client'
      | 'Finalized'
      | 'Trash' = 'InitialContact';
    if (user.type === 'lawyer') {
      // deifne user tag
      const isAdminOfChat = defineIfUserIsAdminChat(user, chat);
      if (isAdminOfChat) {
        if (chat.tag) {
          userTag = chat.tag;
        }
      } else {
        if (chat.additional_information) {
          const value = chat.additional_information?.find(
            item => item.infoName === 'userTag'
          )?.value.tag;
          if (value) {
            userTag = value;
          }
        }
      }
    }

    const NewContact: ChatUserProps = {
      chat_id: `${chat.id}`,
      first_name: userToAdd.data.first_name,
      last_name: userToAdd.data.last_name,
      id: userToAdd.data.id,
      image: url,
      type:
        userToAdd.data.type === 'lawyer'
          ? 'lawyer'
          : userToAdd.data.type === 'user'
          ? 'user'
          : 'user',
      tag: userTag
    };

    if (userToAdd.data.id) {
      SetContacts((n: ChatUserProps[]) => [...n, NewContact]);
    }
  } catch (error) {
    message.error('Erro ao adicionar o usuário');
  }
}
