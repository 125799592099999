import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;

  cursor: pointer;
  padding: 0.5rem 1rem 0.5rem 1rem;

  background-color: ${props => props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: 1rem;

  font-weight: 600;
  color: ${props => props.theme.colors.title};

  transition: all 0.2s ease-in-out;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: ${props => props.theme.colors.whiteOrDark};
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    opacity: 0.5;
  }
`;
