import { shade } from 'polished';

export default {
  theme: 'light',

  colors: {
    primary: '#F0C419',
    primaryDarker: shade(0.05, '#F0C419'),
    background: '#fff',
    backgroundView: '#ffffff',
    title: '#282829',
    text: '#282829',
    lightText: 'grey',
    subTitle: 'grey',
    whiteOrDark: '#fff',
    darkOrWhite: '#2B2B2B',
    white: '#fff',
    lightGrey: 'grey',
    divider: '#D3D3D3',
    dark: '#282829',

    red: '#c53030',

    ChatHeader: '#fff',
    ChatMessages: '#f9f9f9',
    ChatSendArea: '#D3D3D3',

    menu: '#1F1F1F',
    headerDashboard: '#f2f7f7',
    footerDashboard: '#f2f2f2',
    menuDropdown: '#1A1A1A',

    DarkOrWhiteIcon: '#000',
    WhiteIcon: '#000',
    lightGreyIcon: 'grey',

    online: '#219653',

    offline: 'grey',
    membershipOrange: '#F29C1F',
    professionalGrey: 'rgba(106, 106, 106, 0.27)',
    starterGrey: 'rgba(185, 185, 185, 0.27)'
  }
};
