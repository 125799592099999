import { message, notification } from 'antd';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { uuid } from 'uuidv4';
import ToastContainer from '../components/ToastContainer/ToastIndex';
import { useResponsiveness } from './Responsiveness';

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
  ErrorToast(): void;
}

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export const ToastProvider: React.FC = ({ children }) => {
  const { ScreenMD } = useResponsiveness();
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, title, description }: Omit<ToastMessage, 'id'>) => {
      const id = uuid();

      const toast = {
        id,
        type,
        title,
        description
      };

      const Notification = () => {
        notification.open({
          style: { zIndex: 100000000000000 },

          message: title,
          description: description,
          type: type,
          top: ScreenMD ? undefined : 24,
          placement: ScreenMD ? 'bottomRight' : 'topRight'
        });
      };
      Notification();

      // setMessages(oldMessages => [...oldMessages, toast]);
    },
    []
  );

  const ErrorToast = (title?: string) => {
    // addToast({
    //   title: title || 'Houve um erro, tente novamente',
    //   description: description || undefined,
    //   type: 'error'
    // });
    message.error(title);
  };

  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);
  return (
    <ToastContext.Provider value={{ addToast, removeToast, ErrorToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

export function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error(
      'useToast must be used in a children component of ToastProvider'
    );
  }

  return context;
}
